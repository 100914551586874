import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { Button, Typography, Grid, Container, Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStateSelector } from '../state';
import { createAxios } from '../axios';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 15,
  },
  info: {
    paddingBottom: 15,
  },
  card: {
    padding: 10,
    marginBottom: 25,
    marginTop: 20,
  },
}));

const CareRecipientIndexPage = ({ match }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const voucherCode = match.params.voucher;

  const [initialCareRecipient, [setCareRecipient, dispatch]] = useStateSelector(
    data => data.careRecipient
  );

  const [apiVoucher, setApiVoucher] = React.useState();

  const onChangeNextStep = () => {
    dispatch({
      type: 'updateCareRecipient',
      payload: { ...initialCareRecipient },
    });
  };

  React.useEffect(() => {
    const client = createAxios();

    const fetchData = async () => {
      try {
        const response = await client.post(
          '/api/Registration/getCareRecipientData',
          {
            voucher: voucherCode,
          }
        );

        setCareRecipient(response.data.recipient);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // eslint-disable-next-line
  }, [voucherCode]);

  React.useEffect(() => {
    const client = createAxios();

    const fetchData = async () => {
      try {
        const response = await client.post(
          '/api/Registration/checkIfVoucherIsValid',
          {
            voucher: voucherCode,
          }
        );

        if (response.data.isVoucherValid) {
          setApiVoucher(response.data.voucher.couponId);
        }

        return response.data;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // eslint-disable-next-line
  }, [voucherCode]);

  return (
    <Container maxWidth={false}>
      <Grid container justify="center" spacing={2}>
        {apiVoucher && initialCareRecipient !== null ? (
          <Grid item lg={5} xs={12}>
            <Typography className={classes.title} variant="h6">
              {t('IndexPage.Title')}
            </Typography>
            <Typography className={classes.info} variant="subtitle1">
              {t('CareRecipientIndexPage.InfoText')}
            </Typography>
            <Button
              component={Link}
              to="/care-recipient-registration/account/"
              variant="contained"
              color="primary"
              onClick={onChangeNextStep}
            >
              {t('IndexPage.StartRegistration')}
            </Button>
          </Grid>
        ) : (
          <React.Fragment>
            <Card className={classes.card}>
              <Typography variant="subtitle2">
                {t('CareRecipientIndexPage.InvalidVoucherCode')}
              </Typography>
              <Typography variant="subtitle1">
                {t('CareRecipientIndexPage.GivenVoucherCode')}
              </Typography>
              <Typography variant="subtitle2">{voucherCode}</Typography>
              <Typography variant="subtitle1">
                {t('CareRecipientIndexPage.Instructions')}
              </Typography>
            </Card>
          </React.Fragment>
        )}
        {/* <Grid item lg={4} xs={12}>
          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default withRouter(CareRecipientIndexPage);
