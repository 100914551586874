import React from 'react';

import {
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Button,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useForm from 'hooks/momentaryCareRecipientForm';
import StatesProvinceRegions from '../entities/statesProvinceRegions';
import { useTranslation } from 'react-i18next';
import NewCareRecipientFormValidator from './NewCareRecipientFormValidator';
import timezoneOptionsList from '../entities/timezoneOptionsList';
import { DateTime } from 'luxon';
import PhoneInput from 'react-phone-number-input';
import CustomPhoneInput from '../components/PhoneInput';

function NewCareRecipientForm({
  open = false,
  initialValues = {},
  onFormSubmitted = () => {},
  onFormCanceled = () => {},
  info,
  voucherCode,
}) {
  const initialState = Object.freeze({
    hasCarerecipientPermission: false,
    hasLeapThruPermission: false,
    firstname: '',
    lastname: '',
    email: '',
    age: 0,
    phoneNumber: '',
    isPhoneSmarthpone: false,
    whatSmartphone: '',
    smartphoneMakeModel: '',
    address: '',
    secondAddress: '',
    city: '',
    stateProvinceRegion: '',
    zipPostal: '',
    timezone: DateTime.local().toFormat('z'),
    voucher: '',
    maritalStatus: '',
    relationship: '',
    residence: '',
    livingEnvironment: '',
    levelOfIndependence: '',
    orderSmartwatch: false,
    smartwatchDeliveryAddressCarerecipient: false,
    password: '',
    passwordConfirm: '',
  });

  const { t } = useTranslation();
  const [values, setValues, errors, handleChange, handleSubmit] = useForm(
    { ...initialState, ...initialValues },
    () => {
      values.voucher = voucherCode;
      onFormSubmitted(values);
      setValues(initialState);
    },
    values => {
      const errors = NewCareRecipientFormValidator(values);
      return errors;
    }
  );

  const onChangePhoneNumber = phoneNumber => {
    setValues(values => ({
      ...values,
      phoneNumber,
    }));
  };

  function handleOnFormCanceled() {
    onFormCanceled();
    if (!info) {
      setValues(initialState);
    }
  }

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Dialog
        open={open}
        onClose={() => onFormCanceled()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Care Recipient Info</DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="hasCarerecipientPermission"
                    onChange={handleChange}
                    checked={values.hasCarerecipientPermission}
                    value={values.hasCarerecipientPermission}
                    color="primary"
                  />
                }
                label={t(
                  'Registration.Carerecipients.CarerecipientRegistration.Permission'
                )}
                labelPlacement="end"
              />
              <ul>
                <li>
                  <Typography vairant="h6">
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.Permission2'
                    )}
                  </Typography>
                </li>
                <li>
                  <Typography vairant="h6">
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.Permission3'
                    )}
                  </Typography>
                </li>
                <li>
                  <Typography vairant="h6">
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.Permission4'
                    )}
                  </Typography>
                </li>
                <li>
                  <Typography vairant="h6">
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.Permission5'
                    )}
                  </Typography>
                </li>
                <li>
                  <Typography vairant="h6">
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.Permission6'
                    )}
                  </Typography>
                </li>
              </ul>
              <FormControlLabel
                control={
                  <Checkbox
                    name="hasLeapThruPermission"
                    checked={values.hasLeapThruPermission}
                    value={values.hasLeapThruPermission}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={t(
                  'Registration.Carerecipients.CarerecipientRegistration.LeapThruPermission'
                )}
                labelPlacement="end"
              />
              {values.hasCarerecipientPermission &&
              values.hasLeapThruPermission ? (
                <React.Fragment>
                  <TextField
                    error={errors.firstname}
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.Firstname'
                    )}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />

                  <TextField
                    error={errors.lastname}
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.Lastname'
                    )}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />

                  <TextField
                    error={errors.email}
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.Email'
                    )}
                    margin="normal"
                    variant="outlined"
                    type="email"
                    fullWidth
                    required
                  />

                  <TextField
                    error={errors.age}
                    name="age"
                    value={values.age}
                    onChange={handleChange}
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.Age'
                    )}
                    type="number"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                  />
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={values.phoneNumber || ''}
                    onChange={onChangePhoneNumber}
                    handlechange={handleChange}
                    error={errors.phoneNumber || false}
                    name="phoneNumber"
                    inputComponent={CustomPhoneInput}
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.PhoneNumber'
                    )}
                    defaultCountry="US"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isPhoneSmarthpone"
                        checked={values.isPhoneSmarthpone}
                        value={values.isPhoneSmarthpone}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.PhoneCheck'
                    )}
                    labelPlacement="end"
                  />
                  {values.isPhoneSmarthpone ? (
                    <React.Fragment>
                      <TextField
                        select
                        label={t(
                          'Registration.Carerecipients.CarerecipientRegistration.WhatSmartphone'
                        )}
                        name="whatSmartphone"
                        value={values.whatSmartphone}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                      >
                        <MenuItem value="android">
                          {t(
                            'Registration.Carerecipients.CarerecipientRegistration.Android'
                          )}
                        </MenuItem>
                        <MenuItem value="iphone">
                          {t(
                            'Registration.Carerecipients.CarerecipientRegistration.Iphone'
                          )}
                        </MenuItem>
                        <MenuItem value="other">
                          {t(
                            'Registration.Carerecipients.CarerecipientRegistration.Other'
                          )}
                        </MenuItem>
                      </TextField>

                      <TextField
                        name="smartphoneMakeModel"
                        value={values.smartphoneMakeModel}
                        onChange={handleChange}
                        label={t(
                          'Registration.Carerecipients.CarerecipientRegistration.MakeModel'
                        )}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </React.Fragment>
                  ) : null}
                  <TextField
                    error={errors.address}
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.Address'
                    )}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <TextField
                    error={Boolean(errors.secondAddress)}
                    name="secondAddress"
                    value={values.secondAddress}
                    onChange={handleChange}
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.SecondAddress'
                    )}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    error={errors.city}
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.City'
                    )}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <TextField
                    error={errors.stateProvinceRegion}
                    select
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.StateProvinceRegion'
                    )}
                    name="stateProvinceRegion"
                    value={values.stateProvinceRegion}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  >
                    {StatesProvinceRegions.map(item => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    error={errors.zipPostal}
                    name="zipPostal"
                    value={values.zipPostal}
                    onChange={handleChange}
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.ZipPostal'
                    )}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <TextField
                    error={errors.timezone}
                    select
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.Timezone'
                    )}
                    name="timezone"
                    value={values.timezone}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  >
                    {timezoneOptionsList.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </TextField>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ 'margin-top': 20 }}
                  >
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.Disclaimer'
                    )}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.Disclaimer2'
                    )}
                  </Typography>
                  <TextField
                    select
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.MaritalStatus.Title'
                    )}
                    name="maritalStatus"
                    value={values.maritalStatus}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="single">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.MaritalStatus.Menu1'
                      )}
                    </MenuItem>
                    <MenuItem value="married">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.MaritalStatus.Menu2'
                      )}
                    </MenuItem>
                    <MenuItem value="divorced">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.MaritalStatus.Menu3'
                      )}
                    </MenuItem>
                    <MenuItem value="widowed">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.MaritalStatus.Menu4'
                      )}
                    </MenuItem>
                  </TextField>
                  <TextField
                    select
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.Residence.Title'
                    )}
                    name="residence"
                    value={values.residence}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="own-home-or-apartment">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Residence.Menu1'
                      )}
                    </MenuItem>
                    <MenuItem value="independent-living">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Residence.Menu2'
                      )}
                    </MenuItem>
                    <MenuItem value="assisted-living">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Residence.Menu3'
                      )}
                    </MenuItem>
                    <MenuItem value="nursing-home">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Residence.Menu4'
                      )}
                    </MenuItem>
                    <MenuItem value="retirement-facility">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Residence.Menu5'
                      )}
                    </MenuItem>
                  </TextField>
                  <TextField
                    select
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.LivingEnvironment.Title'
                    )}
                    name="livingEnvironment"
                    value={values.livingEnvironment}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="living-alone">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.LivingEnvironment.Menu1'
                      )}
                    </MenuItem>
                    <MenuItem value="living-with-someone">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.LivingEnvironment.Menu2'
                      )}
                    </MenuItem>
                  </TextField>
                  <TextField
                    select
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.Independence.Title'
                    )}
                    name="levelOfIndependence"
                    value={values.levelOfIndependence}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="fully-independent">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Independence.Menu1'
                      )}
                    </MenuItem>
                    <MenuItem value="mostly-independent">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Independence.Menu2'
                      )}
                    </MenuItem>
                    <MenuItem value="mostly-dependent">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Independence.Menu3'
                      )}
                    </MenuItem>
                    <MenuItem value="full-time-assistance">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Independence.Menu4'
                      )}
                    </MenuItem>
                  </TextField>
                  <TextField
                    select
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.Relationship.Title'
                    )}
                    name="relationship"
                    value={values.relationship}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="family-member">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu1'
                      )}
                    </MenuItem>
                    <MenuItem value="friend">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu2'
                      )}
                    </MenuItem>
                    <MenuItem value="spouse">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu3'
                      )}
                    </MenuItem>
                    <MenuItem value="other-relative">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu4'
                      )}
                    </MenuItem>
                    <MenuItem value="other">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu5'
                      )}
                    </MenuItem>
                    <MenuItem value="physician">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu6'
                      )}
                    </MenuItem>
                    <MenuItem value="professional-caregiver">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu7'
                      )}
                    </MenuItem>
                    <MenuItem value="livingStaff">
                      {t(
                        'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu8'
                      )}
                    </MenuItem>
                  </TextField>

                  <Typography variant="subtitle2" gutterBottom>
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.Smartwatch'
                    )}
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.SmartwatchInfo'
                    )}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.SmartwatchInfo2'
                    )}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.SmartwatchInfo3'
                    )}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {t(
                      'Registration.Carerecipients.CarerecipientRegistration.SmartwatchInfo4'
                    )}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="orderSmartwatch"
                        checked={values.orderSmartwatch}
                        value={values.orderSmartwatch}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label={t(
                      'Registration.Carerecipients.CarerecipientRegistration.SmartwatchOrder'
                    )}
                    labelPlacement="end"
                  />
                  {values.orderSmartwatch ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="smartwatchDeliveryAddressCarerecipient"
                          checked={
                            values.smartwatchDeliveryAddressCarerecipient
                          }
                          value={values.smartwatchDeliveryAddressCarerecipient}
                          onChange={handleChange}
                          color="primary"
                        />
                      }
                      label={t(
                        'Registration.Carerecipients.CarerecipientRegistration.SmartwatchDelivery'
                      )}
                      labelPlacement="end"
                    />
                  ) : null}
                </React.Fragment>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleOnFormCanceled()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default NewCareRecipientForm;
