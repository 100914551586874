import React from 'react';
import { setConfig } from 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import TopAppBar from 'components/PageNavigation/TopAppBar';

import LoadingPage from 'pages/LoadingPage';
import routes from './routes';
import i18next from './i18n';
import { StateProvider, initialState, reducer } from './state';

import leapthruMuiTheme from './theme';

const App = () => {
  return (
    <I18nextProvider i18n={i18next}>
      <MuiThemeProvider theme={leapthruMuiTheme}>
        <Router>
          <React.Suspense fallback={<LoadingPage />}>
            <CssBaseline />
            <TopAppBar />
            <SnackbarProvider maxSnack={3}>
              <StateProvider initialState={initialState} reducer={reducer}>
                {routes}
              </StateProvider>
            </SnackbarProvider>
          </React.Suspense>
        </Router>
      </MuiThemeProvider>
    </I18nextProvider>
  );
};

if (process.env.NODE_ENV === 'development') {
  setConfig({
    reloadHooks: false,
  });
}

// eslint-disable-next-line
export default process.env.NODE_ENV === 'development' ? hot(App) : App;
