import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { Button, Typography, Grid, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CarerecipientSettingsSetup from 'components/CarerecipientSettingsSetup/CarerecipientSettingsSetup';
import { createAxios } from '../axios';

import { useStateSelector } from '../state';
import CaregiverStepper from '../components/CaregiverStepper/CaregiverStepper';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 10,
    marginBottom: 25,
    marginTop: 20,
  },
}));

export default function SettingsSetupPage() {
  const { t } = useTranslation();
  const [data] = useStateSelector();

  const [
    careRecipientsData,
    [setCareRecipientsData, dispatch],
  ] = useStateSelector(data => data.carerecipientInfo);

  const classes = useStyles();

  async function sendCaregiverData() {
    const client = createAxios();

    try {
      await client.post(
        '/api/Registration/registerCaregiverAndMomentaryCareRecipients',
        {
          info: { ...data },
          careRecipientInfo: careRecipientsData,
          conditonsOfUseConfirmation: data.confirmation,
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  const onChangeNextStep = () => {
    dispatch({
      type: 'updateCarerecipientInfo',
      payload: careRecipientsData,
    });

    dispatch({
      type: 'upCurrentStep',
    });

    sendCaregiverData();
  };

  const onChangePreviousStep = () => {
    dispatch({
      type: 'updateCarerecipientInfo',
      payload: careRecipientsData,
    });

    dispatch({
      type: 'downCurrentStep',
    });
  };

  const handleCareRecipientInfoUpdate = index => careRecipientSettings => {
    setCareRecipientsData(originalCareRecipient =>
      originalCareRecipient.map((originalCareRecipientInfo, originalIndex) =>
        originalIndex === index
          ? {
              ...originalCareRecipientInfo,
              settings: {
                ...originalCareRecipientInfo.settings,
                ...careRecipientSettings,
              },
            }
          : originalCareRecipientInfo
      )
    );
  };

  const totalNumberOfCareRecipients = careRecipientsData.length;

  return (
    <Container maxWidth={false}>
      <CaregiverStepper currentStep={4} />
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography variant="h6">{t('SettingsSetupPage.Title')}</Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ paddingBottom: 10 }}
          >
            {t('SettingsSetupPage.InfoText')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('SettingsSetupPage.InfoText2')}
          </Typography>
          <div className={classes.card}>
            {careRecipientsData.map((item, index) => (
              <CarerecipientSettingsSetup
                key={index}
                carerecipientInfo={item}
                whatIndex={index}
                onChangeStep={handleCareRecipientInfoUpdate(index)}
                totalNumberOfCareRecipients={totalNumberOfCareRecipients}
              />
            ))}
          </div>
          <Grid container>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-start"
              direction="row"
              item
              xs={6}
            >
              <Button
                component={Link}
                to="/thank-you"
                variant="contained"
                color="primary"
                onClick={onChangePreviousStep}
              >
                {t('SettingsSetupPage.Back')}
              </Button>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-end"
              direction="row"
              item
              xs={6}
            >
              <Button
                component={Link}
                to="/done"
                variant="contained"
                color="primary"
                onClick={onChangeNextStep}
              >
                {t('SettingsSetupPage.Submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item lg={4} xs={12}>
          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
}
