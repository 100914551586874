import React from 'react';

import { Grid, TextField, Button, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useForm from 'hooks/useForm';

import { useTranslation } from 'react-i18next';
import NewPersonOfInterestFormValidator from './NewPersonOfInterestFormValidator';

const initialState = Object.freeze({
  firstname: '',
  lastname: '',
  contactEmail: '',
  phoneNumber: '',
  relationship: '',
});

function NewCareRecipientForm({
  open = false,
  initialValues = {},
  onFormSubmitted = () => {},
  onFormCanceled = () => {},
  info,
  isCareRecipient,
}) {
  const { t } = useTranslation();
  const [values, setValues, errors, handleChange, handleSubmit] = useForm(
    { ...initialState, ...initialValues },
    () => {
      onFormSubmitted(values);
      setValues(initialState);
    },
    values => {
      return NewPersonOfInterestFormValidator(values);
    }
  );

  function handleOnFormCanceled() {
    onFormCanceled();
    if (!info) {
      setValues(initialState);
    }
  }

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Dialog
        open={open}
        onClose={() => onFormCanceled()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t(
            'SettingsSetupPage.CarerecipientsSettingsSetup.PersonsOfInterests'
          )}
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                error={errors.firstname}
                name="firstname"
                value={values.firstname}
                onChange={handleChange}
                label={t(
                  'Registration.Carerecipients.CarerecipientRegistration.Firstname'
                )}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />

              <TextField
                error={errors.lastname}
                name="lastname"
                value={values.lastname}
                onChange={handleChange}
                label={t(
                  'Registration.Carerecipients.CarerecipientRegistration.Lastname'
                )}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />

              <TextField
                error={errors.contactEmail}
                name="contactEmail"
                value={values.contactEmail}
                onChange={handleChange}
                label={t(
                  'Registration.Carerecipients.CarerecipientRegistration.Email'
                )}
                margin="normal"
                variant="outlined"
                type="email"
                fullWidth
              />

              <TextField
                error={errors.phoneNumber}
                name="phoneNumber"
                value={values.phoneNumber}
                onChange={handleChange}
                label={t(
                  'Registration.Carerecipients.CarerecipientRegistration.PhoneNumber'
                )}
                margin="normal"
                variant="outlined"
                fullWidth
                required
                helperText={t(
                  'Registration.Carerecipients.CarerecipientRegistration.PhoneNumberFormatInformation'
                )}
              />

              <TextField
                error={errors.relationship}
                select
                label={t(
                  'SettingsSetupPage.CarerecipientsSettingsSetup.Relationship.Title',
                  { who: isCareRecipient ? 'you' : 'Care Recipient' }
                )}
                name="relationship"
                margin="normal"
                variant="outlined"
                value={values.relationship}
                onChange={handleChange}
                fullWidth
                required
              >
                <MenuItem value="family-member">
                  {t(
                    'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu1'
                  )}
                </MenuItem>
                <MenuItem value="friend">
                  {t(
                    'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu2'
                  )}
                </MenuItem>
                <MenuItem value="spouse">
                  {t(
                    'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu3'
                  )}
                </MenuItem>
                <MenuItem value="other-relative">
                  {t(
                    'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu4'
                  )}
                </MenuItem>
                <MenuItem value="other">
                  {t(
                    'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu5'
                  )}
                </MenuItem>
                <MenuItem value="professional-caregiver">
                  {t(
                    'Registration.Carerecipients.CarerecipientRegistration.Relationship.Menu6'
                  )}
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleOnFormCanceled()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default NewCareRecipientForm;
