import React from 'react';

import { Typography, Grid } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

export default function PersonOfInterest(personOfInterest) {
  const { t } = useTranslation();

  const [values, setValues] = React.useState({ ...personOfInterest });

  React.useEffect(() => {
    setValues(personOfInterest);
  }, [personOfInterest]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="body1">
              {t('Registration.Confirmation.Name')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {`${values.personOfInterest.firstname} ${values.personOfInterest.lastname}`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="body1">
              {t('Registration.Confirmation.Email')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {values.personOfInterest.contactEmail}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="body1">
              {t('Registration.Confirmation.FamilyAndFriends.Relationship')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {values.personOfInterest.relationship}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="body1">
              {t('Registration.Confirmation.PhoneNumber')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {values.personOfInterest.phoneNumber}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
