import React from 'react';
import { makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logoleapthru from 'assets/brand-logos/logoleapthru.svg';

import makeHSL from 'styles/makeHSL';
import TopAppBarMenu from 'components/PageNavigation/TopAppBarMenu';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: '1px solid #e4e8ec',
    boxShadow: 'none',
    marginBottom: 25,
  },

  root: {
    flexGrow: 1,
  },

  grow: {
    flexGrow: 1,
  },

  topbar: {
    width: '100%',
    height: 10,
    background:
      'linear-gradient(to right, hsla(208,51%,51%,1), hsla(180,60%,46%,1))',
  },

  img: {
    paddingTop: 7,
    width: 140,
  },

  active: {
    color: makeHSL({ hue: 210, saturation: 0.55, lightness: 0.19 }),
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: makeHSL({ hue: 180, saturation: 0.6, lightness: 0.46 }),
  },

  dividerContainer: {
    paddingRight: 16,
  },

  divider: {
    width: 1,
    height: 30,
    backgroundColor: makeHSL({ hue: 208, saturation: 0.12, lightness: 0.68 }),
  },
}));

const TopAppBar = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const menuItems = [
    {
      icon: 'home',
      name: t('Home'),
      destination: '/home',
    },
    {
      icon: 'info-circle',
      name: t('About'),
      destination: '/about',
    },
    {
      icon: 'question-circle',
      name: t('Help'),
      destination: '/help',
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.topbar} />
      <AppBar position="static" className={classes.header} color="inherit">
        <Toolbar>
          <img className={classes.img} src={logoleapthru} alt="Leapthru logo" />
          <TopAppBarMenu menuItems={menuItems} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopAppBar;
