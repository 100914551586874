import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import useForm from 'hooks/useForm';
import { validators } from 'validators';
import PhoneInput from 'react-phone-number-input';
import {
  Container,
  Card,
  Button,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStateSelector } from '../state';
import CaregiverStepper from '../components/CaregiverStepper/CaregiverStepper';
import CustomPhoneInput from '../components/PhoneInput';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 10,
    marginBottom: 25,
    marginTop: 20,
  },
}));
const RegistrationAccountInfo = props => {
  const { t } = useTranslation();

  const [initialAccountInfo, [, dispatch]] = useStateSelector(
    data => data.accountInfo
  );

  const onChangeNextStep = () => {
    setValues(accountInfo);
    dispatch({
      type: 'updateAccountInfo',
      payload: { ...accountInfo },
    });

    dispatch({
      type: 'upCurrentStep',
    });

    props.history.push('/registration/carerecipients');
  };

  const onChangePreviousStep = () => {
    dispatch({
      type: 'updateAccountInfo',
      payload: { ...accountInfo },
    });

    dispatch({
      type: 'downCurrentStep',
    });
  };

  const onChangePhoneNumber = phoneNumber => {
    setValues({ phoneNumber });
  };

  const [accountInfo, setValues, errors, handleChange, handleSubmit] = useForm(
    initialAccountInfo,
    onChangeNextStep,
    validators.AccountInfoValidation
  );

  const classes = useStyles();
  return (
    <Container maxWidth={false}>
      <CaregiverStepper currentStep={1} />
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container justify="center" spacing={2}>
          <Grid item lg={5} xs={12}>
            <Typography variant="h6">
              {t('Registration.AccountInfo.Title')}
            </Typography>
            <Typography variant="body1">
              {t('Registration.AccountInfo.InfoText')}
            </Typography>
            <Card className={classes.card} style={{ padding: 10 }}>
              <PhoneInput
                placeholder="Enter phone number"
                value={accountInfo.phoneNumber || ''}
                onChange={onChangePhoneNumber}
                error={errors.phoneNumber || false}
                inputComponent={CustomPhoneInput}
                label={t('Registration.AccountInfo.PhoneNumber')}
                defaultCountry="US"
              />
              <TextField
                error={errors.email}
                name="email"
                value={accountInfo.email}
                onChange={handleChange}
                label={t('Registration.AccountInfo.Email')}
                margin="normal"
                variant="outlined"
                type="email"
                fullWidth
                required
              />
              <TextField
                error={errors.password}
                name="password"
                value={accountInfo.password}
                onChange={handleChange}
                label={t('Registration.AccountInfo.Password')}
                margin="normal"
                variant="outlined"
                type="password"
                fullWidth
                required
              />
              <TextField
                error={errors.passwordConfirm}
                name="passwordConfirm"
                value={accountInfo.passwordConfirm}
                onChange={handleChange}
                label={t('Registration.AccountInfo.PasswordConfirm')}
                margin="normal"
                variant="outlined"
                type="password"
                fullWidth
                required
              />
            </Card>
            <Grid container>
              <Grid
                container
                alignItems="flex-start"
                justify="flex-start"
                direction="row"
                item
                xs={6}
              >
                <Button
                  component={Link}
                  to="/registration/personal-info"
                  variant="outlined"
                  color="primary"
                  onClick={onChangePreviousStep}
                >
                  {t('Registration.Previous')}
                </Button>
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                justify="flex-end"
                direction="row"
                item
                xs={6}
              >
                <Button variant="contained" color="primary" type="submit">
                  {t('Registration.Next')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item lg={4} xs={12}>
            <Typography variant="h6">Some text above the image</Typography>

            <img src={logoleapthru} alt="Leapthru logo" />
          </Grid> */}
        </Grid>
      </form>
    </Container>
  );
};

export default withRouter(RegistrationAccountInfo);
