import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';

import {
  Container,
  Card,
  Button,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStateSelector } from '../state';
import CareRecipientStepper from '../components/CareRecipientStepper/CareRecipientStepper';
import CompanyPolicyDialog from '../dialogs/CompanyPolicyDialog';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 10,
    marginBottom: 25,
    marginTop: 20,
  },
}));

export default function CareRecipientAccountRegistrationConfirmationPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [data, [setData, dispatch]] = useStateSelector(data => data);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleCheckedChange = datum => event => {
    setData({
      ...data,
      confirmation: {
        ...data.confirmation,
        [datum]: event.target.checked,
        date: DateTime.local().toJSDate(),
      },
    });
  };

  const onChangeNextStep = () => {
    dispatch({
      type: 'updateConfirmInfo',
      payload: { ...data.confirmation },
    });
  };

  const handleDialogOpen = () => {
    setDialogOpen(false);
  };

  const carerecipientName = `${data.careRecipient.firstname} ${data.careRecipient.lastname}`;

  return (
    <Container maxWidth={false}>
      <CareRecipientStepper currentStep={1} />
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography variant="h6">
            {t('CareRecipientRegistration.Confirmation.Title')}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t('Registration.Confirmation.InfoText')}
          </Typography>
          <Card className={classes.card}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.Name')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">{carerecipientName}</Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.Email')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.careRecipient.email}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t(
                    'Registration.Carerecipients.CarerecipientRegistration.Age'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.careRecipient.age}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.PhoneNumber')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.careRecipient.phoneNumber}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.Address')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.careRecipient.adress}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.SecondAddress')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.careRecipient.secondAddress}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.ZipPostal')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.careRecipient.zipPostal}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.StateProvinceRegion')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.careRecipient.stateProvinceRegion}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.City')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.careRecipient.city}
                </Typography>
              </Grid>
            </Grid>

            <Typography
              variant="subtitle2"
              style={{ paddingTop: 15, paddingBottom: 15 }}
            >
              {t('CareRecipientRegistration.AgreementInfo')}
            </Typography>

            <Typography variant="subtitle2" style={{ paddingButton: 15 }}>
              {t('CareRecipientRegistration.AgreementInfo2')}
            </Typography>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckedChange('conditionsOfUse')}
                    checked={data.confirmation.conditionsOfUse}
                    value={data.confirmation.conditionsOfUse}
                    color="primary"
                  />
                }
              />
              <Button
                onClick={() =>
                  setDialogOpen({
                    isOpen: true,
                    type: 'termsConditions',
                  })
                }
                style={{ color: 'blue' }}
              >
                {t('Registration.Confirmation.Conditions')}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckedChange('privacyPolicy')}
                    checked={data.confirmation.privacyPolicy}
                    value={data.confirmation.privacyPolicy}
                    color="primary"
                  />
                }
              />
              <Button
                onClick={() =>
                  setDialogOpen({
                    isOpen: true,
                    type: 'privacyPolicy',
                  })
                }
                style={{ color: 'blue' }}
              >
                {t('Registration.Confirmation.Privacy')}
              </Button>
            </Grid>
          </Card>
          <CompanyPolicyDialog
            open={dialogOpen.isOpen}
            type={dialogOpen.type}
            onClose={handleDialogOpen}
          />
          <Grid container>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-start"
              direction="row"
              item
              xs={6}
            >
              <Button
                component={Link}
                to="/care-recipient-registration/account/"
                variant="outlined"
                color="primary"
              >
                {t('Registration.Previous')}
              </Button>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-end"
              direction="row"
              item
              xs={6}
            >
              {data.confirmation.conditionsOfUse &&
              data.confirmation.privacyPolicy ? (
                <Button
                  component={Link}
                  to="/care-recipient/thank-you"
                  variant="contained"
                  color="primary"
                  onClick={onChangeNextStep}
                >
                  {t('Registration.Confirmation.Continue')}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item lg={4} xs={12}>
          <Typography variant="h6">Some text above the image</Typography>

          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
}
