import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { Button, Typography, Grid, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CareRecipientStepper from '../components/CareRecipientStepper/CareRecipientStepper';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 15,
  },
  info: {
    paddingBottom: 15,
  },
}));

export default function CareRecipientThankYou() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <CareRecipientStepper currentStep={2} />
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography className={classes.title} variant="h6">
            {t('CareRecipientRegistration.ThankYou.Title')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.ThankYou.InfoText1')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.ThankYou.InfoText2')}
          </Typography>
          <Grid container>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-start"
              direction="row"
              item
              xs={1}
            >
              <Button
                component={Link}
                to="/care-recipient/done"
                variant="outlined"
                color="primary"
              >
                {t('ThankYouPage.Skip')}
              </Button>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-end"
              direction="row"
              item
              xs={11}
            >
              <Button
                component={Link}
                to="/care-recipient-registration/settings"
                variant="contained"
                color="primary"
                // onClick={onChangeNextStep}
              >
                {t('ThankYouPage.Proceed')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item lg={4} xs={12}>
          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
}
