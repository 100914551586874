export default function RegistrationPersonalInfoValidator(values) {
  const errors = {};

  if (values.voucher.length < 1) {
    errors.voucher = true;
  }
  if (values.firstname.length < 1) {
    errors.firstname = true;
  }
  if (values.lastname.length < 1) {
    errors.lastname = true;
  }
  if (values.address.length < 1) {
    errors.address = true;
  }
  if (values.city.length < 1) {
    errors.city = true;
  }
  if (values.stateProvinceRegion.length < 1) {
    errors.stateProvinceRegion = true;
  }
  if (values.zipPostal.length < 1) {
    errors.zipPostal = true;
  }
  if (values.timezone.length < 1) {
    errors.timezone = true;
  }
  return errors;
}
