import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Prompt } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  stepper: {
    backgroundColor: 'transparent',
  },
}));

function getSteps() {
  return [
    'Personal Info',
    'Account Info',
    'Add Care Recipients',
    'Review Details',
    'Settings',
    'Done',
  ];
}

const urlToWarnBeforeEnter = ['/about', '/help'];

const CaregiverStepper = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const { voucherStepCompleted } = props;

  React.useEffect(() => {
    setActiveStep(props.currentStep);
  }, [props.currentStep]);

  const currentlyRegistering = () =>
    voucherStepCompleted ||
    (activeStep > 0 && activeStep !== getSteps().length - 1);

  return (
    <div className={classes.root}>
      <Prompt
        message={(location, _) => {
          return currentlyRegistering() &&
            urlToWarnBeforeEnter.includes(location.pathname)
            ? t('DataMightWillBeLostPrompt')
            : true;
        }}
      />
      <Stepper className={classes.stepper} activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default CaregiverStepper;
