import { forwardRef } from 'react';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import 'react-phone-number-input/style.css';

const phoneInput = (props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      size="small"
      variant="outlined"
      name="phoneNumber"
    />
  );
};

export default forwardRef(phoneInput);
