import React from 'react';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import fontawesomeIcons from './icons';
import { configureCrispChat } from './crisp';
import App from './App';

library.add(fontawesomeIcons);

configureCrispChat();

ReactDOM.render(<App />, document.getElementById('root'));
