import React from 'react';

import {
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  InputLabel,
  IconButton,
  Divider,
  Button,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { useStateSelector } from '../../state';

import { useTranslation } from 'react-i18next';
import useDebounce from 'hooks/useDebounce';
import NewPersonOfInterestForm from 'forms/NewPersonOfInterestForm';
import PersonOfInterest from 'components/PersonOfInterest/PersonOfInterest';

const useStyles = makeStyles(theme => ({
  expansionPanel: {
    marginBottom: 10,
  },
  timePicker: {
    width: 150,
  },
}));

export default function CarerecipientSettingsSetup({
  onChangeStep = () => {},
  carerecipientInfo,
  whatIndex,
  totalNumberOfCareRecipients,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [values, setValues] = React.useState({ ...carerecipientInfo.settings });
  const [careRecipient, [setCareRecipientData, dispatch]] = useStateSelector(
    data => data.carerecipientInfo[whatIndex]
  );

  const handleAnyChange = (key, value) =>
    setValues({ ...values, [key]: value });

  const handleCheckedChange = value => event =>
    handleAnyChange(value, event.target.checked);

  const handleChange = value => event => {
    handleAnyChange(value, event.target.value);
  };

  const changeStepEffect = () => onChangeStep(values);

  const debouncedValues = useDebounce(values);

  React.useEffect(changeStepEffect, [debouncedValues]);

  const timeFieldStepper = 300;

  const DeleteCareRecipient = ({ index }) => (
    <IconButton color="secondary" onClick={handleOnRemove(index)}>
      <DeleteIcon />
    </IconButton>
  );

  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const handleNewPersonOfInterestCanceled = () => {
    setIsOpen(false);
  };

  const handleNewPersonOfInterest = info => {
    let defaultCareRecipient = {
      ...info,
    };

    dispatch({
      type: 'updateCareRecipient',
      payload: {
        ...careRecipient,
        personsOfInterests: careRecipient.personsOfInterests.push(
          defaultCareRecipient
        ),
      },
    });

    setIsOpen(false);
  };

  const handleOnRemove = indexToRemove => () => {
    setCareRecipientData(orig => ({
      ...orig,
      personsOfInterests: orig.personsOfInterests.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  return (
    <React.Fragment>
      <ExpansionPanel
        className={classes.expansionPanel}
        defaultExpanded={totalNumberOfCareRecipients === 1}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle2">
            {`${careRecipient.firstname} ${careRecipient.lastname}`}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid item xs={12}>
            <TextField
              value={values.homeAddress}
              onChange={handleChange('homeAddress')}
              label={t(
                'SettingsSetupPage.CarerecipientsSettingsSetup.HomeAddress'
              )}
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <Grid container spacing={2} justify="center">
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <TextField
                    name="activeHoursStart"
                    label={t(
                      'SettingsSetupPage.CarerecipientsSettingsSetup.GenerallyWakingUp'
                    )}
                    type="time"
                    onChange={handleChange('activeHoursStart')}
                    defaultValue={values.activeHoursStart}
                    value={values.activeHoursStart}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: timeFieldStepper,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="activeHoursEnd"
                    label={t(
                      'SettingsSetupPage.CarerecipientsSettingsSetup.GenerallyGoingToBed'
                    )}
                    type="time"
                    onChange={handleChange('activeHoursEnd')}
                    defaultValue={values.activeHoursEnd}
                    value={values.activeHoursEnd}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: timeFieldStepper,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckedChange('homeAllDay')}
                    checked={values.homeAllDay}
                    value={values.homeAllDay}
                    color="primary"
                  />
                }
                label={t(
                  'SettingsSetupPage.CarerecipientsSettingsSetup.HomeAllDay'
                )}
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckedChange('atHospital')}
                    checked={values.atHospital}
                    value={values.atHospital}
                    color="primary"
                  />
                }
                label={t(
                  'SettingsSetupPage.CarerecipientsSettingsSetup.AtHospital'
                )}
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckedChange('emergencyBreakThruCalls')}
                    checked={values.emergencyBreakThruCalls}
                    value={values.emergencyBreakThruCalls}
                    color="primary"
                  />
                }
                label={t(
                  'SettingsSetupPage.CarerecipientsSettingsSetup.EmergencyBreakThruCalls'
                )}
                labelPlacement="end"
              />
              <Typography
                variant="body2"
                gutterBottom
                style={{ paddingBottom: 10 }}
              >
                {t(
                  'SettingsSetupPage.CarerecipientsSettingsSetup.BreakThruInfoText'
                )}
              </Typography>
            </Grid>
            <InputLabel htmlFor="select-multiple-checkbox">
              {t(
                'SettingsSetupPage.CarerecipientsSettingsSetup.PersonsOfInterests'
              ) + ':'}
            </InputLabel>
            <br />
            <InputLabel htmlFor="select-multiple-checkbox">
              {t(
                'SettingsSetupPage.CarerecipientsSettingsSetup.PersonsOfInterestsInfoText',
                { careRecipientFirstName: careRecipient.firstname }
              )}
            </InputLabel>
            <br />
            <Tooltip
              title={t(
                'SettingsSetupPage.CarerecipientsSettingsSetup.PersonsOfInterestsInfoText2'
              )}
              placement="right"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
              >
                {t(
                  'SettingsSetupPage.CarerecipientsSettingsSetup.AddPersonOfInterest'
                )}
              </Button>
            </Tooltip>
            <br />
            <br />
            <NewPersonOfInterestForm
              open={isOpen}
              onFormSubmitted={handleNewPersonOfInterest}
              onFormCanceled={handleNewPersonOfInterestCanceled}
            />
            {careRecipient.personsOfInterests.length > 0 ? (
              <ExpansionPanel className={classes.expansionPanel}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="subtitle2">
                    {t(
                      'SettingsSetupPage.CarerecipientsSettingsSetup.PersonsOfInterests'
                    )}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid justify="center" spacing={2} item xs={12}>
                    {careRecipient.personsOfInterests.map(
                      (personOfInterest, index) => (
                        <React.Fragment>
                          <Grid container item xs={12}>
                            <Grid item xs={11}>
                              <PersonOfInterest
                                personOfInterest={personOfInterest}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <DeleteCareRecipient key={index} index={index} />
                            </Grid>
                          </Grid>
                          <Divider />
                        </React.Fragment>
                      )
                    )}
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : null}
            <InputLabel htmlFor="select-multiple-checkbox">
              {t(
                'SettingsSetupPage.CarerecipientsSettingsSetup.BestTimes.Title'
              )}
            </InputLabel>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={3}>
                <FormControlLabel
                  onChange={handleCheckedChange('isAvailableInAnytime')}
                  checked={values.isAvailableInAnytime}
                  value={values.isAvailableInAnytime}
                  control={<Checkbox color="primary" />}
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.BestTimes.Anytime'
                  )}
                  labelPlacement="bottom"
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange('isAvailableInMorning')}
                      checked={values.isAvailableInMorning}
                      value={values.isAvailableInMorning}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.BestTimes.Morning'
                  )}
                  labelPlacement="bottom"
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange('isAvailableInAfternoon')}
                      checked={values.isAvailableInAfternoon}
                      value={values.isAvailableInAfternoon}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.BestTimes.Afternoon'
                  )}
                  labelPlacement="bottom"
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange('isAvailableInEvening')}
                      checked={values.isAvailableInEvening}
                      value={values.isAvailableInEvening}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.BestTimes.Evening'
                  )}
                  labelPlacement="bottom"
                />
              </Grid>
            </Grid>
            <InputLabel htmlFor="select-multiple-checkbox">
              {t(
                'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.Title'
              )}
            </InputLabel>
            <Grid container justify="center">
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange(
                        'isInterestedInEntertainment'
                      )}
                      checked={values.isInterestedInEntertainment}
                      value={values.isInterestedInEntertainment}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.Entertainment'
                  )}
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange('isInterestedInNews')}
                      checked={values.isInterestedInNews}
                      value={values.isInterestedInNews}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.News'
                  )}
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange(
                        'isInterestedInGamesPuzzle'
                      )}
                      checked={values.isInterestedInGamesPuzzle}
                      value={values.isInterestedInGamesPuzzle}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.GamesPuzzle'
                  )}
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange(
                        'isInterestedInHealthyLiving'
                      )}
                      checked={values.isInterestedInHealthyLiving}
                      value={values.isInterestedInHealthyLiving}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.HealthyLiving'
                  )}
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange(
                        'isInterestedInHomeGardening'
                      )}
                      checked={values.isInterestedInHomeGardening}
                      value={values.isInterestedInHomeGardening}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.HomeGarden'
                  )}
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange('isInterestedInPolitics')}
                      checked={values.isInterestedInPolitics}
                      value={values.isInterestedInPolitics}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.Politics'
                  )}
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange('isInterestedInSports')}
                      checked={values.isInterestedInSports}
                      value={values.isInterestedInSports}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.Sports'
                  )}
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckedChange('isInterestedInTechnology')}
                      checked={values.isInterestedInTechnology}
                      value={values.isInterestedInTechnology}
                      color="primary"
                    />
                  }
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.Technology'
                  )}
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </React.Fragment>
  );
}
