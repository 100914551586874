import { isPossiblePhoneNumber } from 'react-phone-number-input';

export default function RegistrationCareRecipients(values) {
  const errors = {};

  if (values.hasCarerecipientPermission === true) {
    errors.hasCarerecipientPermission = true;
  }
  if (values.firstname.length < 1) {
    errors.firstname = true;
  }
  if (values.lastname.length < 1) {
    errors.lastname = true;
  }
  if (values.email.length < 1) {
    errors.email = true;
  }
  if (values.age.length < 1) {
    errors.age = true;
  }
  if (!isPossiblePhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = true;
  }
  if (values.smartphoneMakeModel.length < 1) {
    errors.smartphoneMakeModel = true;
  }
  if (values.address.length < 1) {
    errors.address = true;
  }
  if (values.city.length < 1) {
    errors.city = true;
  }
  if (values.stateProvinceRegion.length < 1) {
    errors.stateProvinceRegion = true;
  }
  if (values.zipPostal.length < 1) {
    errors.zipPostal = true;
  }
  if (values.voucher.length < 1) {
    errors.voucher = true;
  }
  if (values.relationship.length < 1) {
    errors.relationship = true;
  }
  if (values.residence.length < 1) {
    errors.residence = true;
  }
  if (values.levelOfIndependence.length < 1) {
    errors.levelOfIndependence = true;
  }

  return errors;
}
