import { useState, useEffect } from 'react';
import { createAxios } from '../axios';

async function validateEmail(email) {
  const client = createAxios();

  try {
    const response = await client.post(
      '/api/Registration/checkIfEmailIsValid',
      {
        email: email,
      }
    );
    if (!response.data.isEmailAvailable) {
      return { email: !response.data.isEmailAvailable };
    }

    return {};
  } catch (error) {
    console.error({ email: true });
  }
}

const useForm = (initialValues = {}, callback, validate) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    setIsSubmitting(false);
  }, [callback, errors, isSubmitting]);

  const handleSubmit = async event => {
    if (event) event.preventDefault();

    const errors = validate(values);
    if (values.email) {
      const asyncErrors = await validateEmail(values.email);
      setErrors({ ...asyncErrors, ...errors });
    }
    if (!values.email) {
      setErrors({ ...errors });
    }

    setIsSubmitting(true);
  };

  const handleChange = event => {
    event.persist();

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setValues(values => ({
      ...values,
      [target.name]: value,
    }));
  };

  return [values, setValues, errors, handleChange, handleSubmit];
};

export default useForm;
