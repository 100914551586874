import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';

import {
  Container,
  Card,
  Button,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RegistrationConfirmationCareRecipients from 'components/RegistrationConfirmationCareRecipients/RegistrationConfirmationCareRecipients';
import { useStateSelector } from '../state';
import CaregiverStepper from '../components/CaregiverStepper/CaregiverStepper';
import CompanyPolicyDialog from '../dialogs/CompanyPolicyDialog';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 10,
    marginBottom: 25,
    marginTop: 20,
  },
}));

export default function RegistrationConfirmation() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [data, [setData, dispatch]] = useStateSelector();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleCheckedChange = datum => event => {
    setData({
      ...data,
      confirmation: {
        ...data.confirmation,
        [datum]: event.target.checked,
        date: DateTime.local().toJSDate(),
      },
    });
  };

  const onChangeNextStep = () => {
    dispatch({
      type: 'updateConfirmInfo',
      payload: { ...data.confirmation },
    });

    dispatch({
      type: 'upCurrentStep',
    });
  };

  const onChangePreviousStep = () => {
    dispatch({
      type: 'updateConfirmInfo',
      payload: { ...data.confirmation },
    });

    dispatch({
      type: 'downCurrentStep',
    });
  };

  const handleDialogOpen = () => {
    setDialogOpen(false);
  };

  return (
    <Container maxWidth={false}>
      <CaregiverStepper currentStep={3} />
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography variant="h6">
            {t('Registration.Confirmation.Title')}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t('Registration.Confirmation.InfoText')}
          </Typography>
          <Card className={classes.card}>
            <Typography variant="subtitle2" gutterBottom>
              Main Caregiver
            </Typography>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.Name')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.personalInfo.firstname +
                    ' ' +
                    data.personalInfo.lastname}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.Email')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.accountInfo.email}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.PhoneNumber')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.accountInfo.phoneNumber}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.Address')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.personalInfo.address}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.SecondAddress')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.personalInfo.secondAddress}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.City')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.personalInfo.city}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.StateProvinceRegion')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.personalInfo.stateProvinceRegion}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.ZipPostal')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.personalInfo.zipPostal}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">Timezone</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {data.personalInfo.timezone}
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="subtitle2">
              {t('Registration.Confirmation.Carerecipients')}
            </Typography>
            {data.carerecipientInfo.map((item, index) => (
              <React.Fragment>
                <RegistrationConfirmationCareRecipients
                  key={index}
                  carerecipientInfo={item}
                />
              </React.Fragment>
            ))}
            <Typography variant="subtitle2" style={{ paddingBottom: 15 }}>
              {t('Registration.Confirmation.AgreementInfo')}
            </Typography>
            <Typography variant="subtitle2">
              {t('Registration.Confirmation.AgreementInfo2')}
            </Typography>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckedChange('conditionsOfUse')}
                    checked={data.confirmation.conditionsOfUse}
                    value={data.confirmation.conditionsOfUse}
                    color="primary"
                  />
                }
              />
              <Button
                onClick={() =>
                  setDialogOpen({
                    isOpen: true,
                    type: 'termsConditions',
                  })
                }
                style={{ color: 'blue' }}
              >
                {t('Registration.Confirmation.Conditions')}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckedChange('privacyPolicy')}
                    checked={data.confirmation.privacyPolicy}
                    value={data.confirmation.privacyPolicy}
                    color="primary"
                  />
                }
              />
              <Button
                onClick={() =>
                  setDialogOpen({
                    isOpen: true,
                    type: 'privacyPolicy',
                  })
                }
                style={{ color: 'blue' }}
              >
                {t('Registration.Confirmation.Privacy')}
              </Button>
            </Grid>
          </Card>
          <CompanyPolicyDialog
            open={dialogOpen.isOpen}
            type={dialogOpen.type}
            onClose={handleDialogOpen}
          />
          <Grid container>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-start"
              direction="row"
              item
              xs={6}
            >
              <Button
                component={Link}
                to="/registration/carerecipients"
                variant="outlined"
                color="primary"
                onClick={onChangePreviousStep}
              >
                {t('Registration.Previous')}
              </Button>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-end"
              direction="row"
              item
              xs={6}
            >
              {data.confirmation.conditionsOfUse &&
              data.confirmation.privacyPolicy ? (
                <Button
                  component={Link}
                  to="/thank-you"
                  variant="contained"
                  color="primary"
                  onClick={onChangeNextStep}
                >
                  {t('Registration.Confirmation.Continue')}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item lg={4} xs={12}>
          <Typography variant="h6">Some text above the image</Typography>

          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
}
