import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useStateSelector } from '../state';

import { Button, Typography, Grid, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { createAxios } from '../axios';
import CaregiverStepper from '../components/CaregiverStepper/CaregiverStepper';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 15,
  },
  info: {
    paddingBottom: 15,
  },
}));

export default function ThankYouPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [data] = useStateSelector();
  const [careRecipientsData, [, dispatch]] = useStateSelector(
    data => data.carerecipientInfo
  );

  async function sendCaregiverData() {
    const client = createAxios();

    try {
      await client.post(
        '/api/Registration/registerCaregiverAndMomentaryCareRecipients',
        {
          info: { ...data },
          careRecipientInfo: careRecipientsData,
          conditonsOfUseConfirmation: data.confirmation,
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  const onChangeNextStep = () => {
    dispatch({
      type: 'upCurrentStep',
    });
  };

  const onSkipChange = () => {
    dispatch({
      type: 'upCurrentStep',
    });
    dispatch({
      type: 'upCurrentStep',
    });
    sendCaregiverData();
  };

  return (
    <Container maxWidth={false}>
      <CaregiverStepper currentStep={4} />
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography className={classes.title} variant="h6">
            {t('ThankYouPage.Title')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('ThankYouPage.InfoText1')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('ThankYouPage.InfoText2')}
          </Typography>

          <Grid container>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-start"
              direction="row"
              item
              xs={1}
            >
              <Button
                component={Link}
                to="/done"
                variant="outlined"
                color="primary"
                onClick={onSkipChange}
              >
                {t('ThankYouPage.Skip')}
              </Button>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-end"
              direction="row"
              item
              xs={11}
            >
              <Button
                component={Link}
                to="/settings-setup"
                variant="contained"
                color="primary"
                onClick={onChangeNextStep}
              >
                {t('ThankYouPage.Proceed')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item lg={4} xs={12}>
          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
}
