import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, Grid, Container, Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStateSelector } from '../state';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 15,
  },
  info: {
    paddingBottom: 15,
  },
  card: {
    marginBottom: 25,
    marginTop: 20,
  },
  namePadding: {
    padding: 10,
  },
}));

export default function HelpCareRecipientRegistrationPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [data] = useStateSelector();
  const [selectedRecipient, setSelectedRecipient] = useState(null);

  const handleSelectButtonClick = item => () => {
    setSelectedRecipient(item);
  };

  return (
    <Container maxWidth={false}>
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography className={classes.title} variant="h6">
            {t('HelpCareRecipientsRegisterPage.Title')}
          </Typography>

          {data.carerecipientInfo.map((item, index) => (
            <Card className={classes.card}>
              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                alignItems="center"
              >
                <Grid item xs={10}>
                  <Typography className={classes.namePadding} variant="h6">
                    {item.firstname + ' ' + item.lastname}
                  </Typography>
                </Grid>
                <Grid item xs={2} justify="center">
                  <Button
                    component={Link}
                    to={'/care-recipient-registration/start/' + item.voucher}
                    target="_blank"
                    variant="contained"
                    color="primary"
                    onClick={handleSelectButtonClick(item)}
                    disabled={selectedRecipient === item}
                  >
                    {selectedRecipient === item
                      ? t('HelpCareRecipientsRegisterPage.Registered')
                      : t('HelpCareRecipientsRegisterPage.Select')}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
