import axios from 'axios';

const axiosIspyResponseInterceptor = response => {
  const status = response.data.status;

  if (status >= 400) {
    const { data, ...transformedAxiosResponse } = response;
    let errors = { statusText: 'Unknown', status: 400 };

    if (data.error) {
      errors = {
        statusText: data.error,
        error: data.error,
      };
    }

    return Promise.reject({
      response: {
        ...transformedAxiosResponse,
        status,
        ...errors,
        data,
      },
    });
  }

  return {
    ...response,
    data: response.data.data,
  };
};

export const createAxios = (options = {}) => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_URL_SERVER_BACKEND,
    ...options,
  });

  client.interceptors.response.use(axiosIspyResponseInterceptor);

  return client;
};
