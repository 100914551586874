import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { Button, Typography, Grid, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStateSelector } from '../state';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 15,
  },
  info: {
    paddingBottom: 15,
  },
}));

export default function IndexPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [, [, dispatch]] = useStateSelector();

  const onChangeStep = () => {
    dispatch({
      type: 'upCurrentStep',
    });
  };
  return (
    <Container maxWidth={false}>
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography className={classes.title} variant="h6">
            {t('IndexPage.Title')}
          </Typography>
          <Typography className={classes.info} variant="subtitle1">
            {t('IndexPage.InfoText2')}
          </Typography>
          <Typography className={classes.info} variant="subtitle1">
            {t('IndexPage.InfoText3')}
          </Typography>
          <Button
            component={Link}
            to="/registration/personal-info"
            variant="contained"
            color="primary"
            onClick={onChangeStep}
          >
            {t('IndexPage.StartRegistration')}
          </Button>
        </Grid>
        {/* <Grid item lg={4} xs={12}>
          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
}
