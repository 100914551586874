import { isPossiblePhoneNumber } from 'react-phone-number-input';

export default function RegistrationAccountInfo(values) {
  const errors = {};

  if (values.email.length < 1) {
    errors.email = true;
  }
  if (!isPossiblePhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = true;
  }
  if (values.password.length < 1) {
    errors.password = true;
  }
  if (values.passwordConfirm.length < 1) {
    errors.passwordConfirm = true;
  }

  if (values.passwordConfirm !== values.password) {
    errors.password = true;
    errors.passwordConfirm = true;
  }

  return errors;
}
