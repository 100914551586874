import React from 'react';

import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  expansionPanel: {
    marginBottom: 10,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));

export default function RegistrationConfirmationCareRecipients({
  carerecipientInfo,
  totalNumberOfCareRecipients,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [values, setValues] = React.useState(carerecipientInfo);

  React.useEffect(() => {
    setValues(carerecipientInfo);
  }, [carerecipientInfo]);
  return (
    <React.Fragment>
      <ExpansionPanel
        className={classes.expansionPanel}
        defaultExpanded={totalNumberOfCareRecipients === 1}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle2">
            {values.firstname + ' ' + values.lastname}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid item xs={12}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.Name')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {values.firstname + ' ' + values.lastname}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.Email')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{values.email}</Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.CareRecipientSpecific.Age')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{values.age}</Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.PhoneNumber')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {values.phoneNumber}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t(
                    'Registration.Confirmation.CareRecipientSpecific.WhatSmartphone'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.capitalize} variant="subtitle2">
                  {values.whatSmartphone}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t(
                    'Registration.Confirmation.CareRecipientSpecific.MakeModel'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {values.smartphoneMakeModel}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.Address')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{values.address}</Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.SecondAddress')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {values.secondAddress}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.City')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{values.city}</Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.StateProvinceRegion')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {values.stateProvinceRegion}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('Registration.Confirmation.ZipPostal')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">{values.zipPostal}</Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t(
                    'Registration.Confirmation.CareRecipientSpecific.MaritalStatus'
                  )}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography className={classes.capitalize} variant="subtitle2">
                  {values.maritalStatus}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t(
                    'Registration.Confirmation.CareRecipientSpecific.Relationship'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.capitalize} variant="subtitle2">
                  {values.relationship}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t(
                    'Registration.Confirmation.CareRecipientSpecific.Residence'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.capitalize} variant="subtitle2">
                  {values.residence.replace(/-/g, ' ')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t(
                    'Registration.Confirmation.CareRecipientSpecific.LivingEnvironment'
                  )}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography className={classes.capitalize} variant="subtitle2">
                  {values.livingEnvironment.replace(/-/g, ' ')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t(
                    'Registration.Confirmation.CareRecipientSpecific.Independence'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.capitalize} variant="subtitle2">
                  {values.levelOfIndependence.replace(/-/g, ' ')}
                </Typography>
              </Grid>
            </Grid>
            {values.orderSmartwatch ? (
              <React.Fragment>
                <Typography variant="subtitle2" gutterBottom>
                  {t(
                    'Registration.Confirmation.CareRecipientSpecific.Smartwatch'
                  )}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {values.smartwatchDeliveryAddressCarerecipient
                    ? t(
                        'Registration.Confirmation.CareRecipientSpecific.SmartwatchCareRecipientDelivery',
                        {
                          firstname: values.firstname,
                          lastname: values.lastname,
                        }
                      )
                    : t(
                        'Registration.Confirmation.CareRecipientSpecific.SmartwatchCaregiverDelivery'
                      )}
                </Typography>
              </React.Fragment>
            ) : null}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </React.Fragment>
  );
}
