import React from 'react';

import { Button, Typography, Grid, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { createAxios } from '../axios';
import { useStateSelector } from '../state';
import CareRecipientStepper from '../components/CareRecipientStepper/CareRecipientStepper';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 15,
  },
  info: {
    paddingBottom: 15,
  },
}));

export default function DonePage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [data] = useStateSelector(data => data);
  const [user, setUser] = React.useState();
  const caregiverUserId = data.careRecipient.primaryCareGiver;
  React.useEffect(() => {
    const client = createAxios();

    const fetchData = async () => {
      try {
        const response = await client.post(
          '/api/Registration/getCaregiverData',
          {
            caregiverUserId: caregiverUserId,
          }
        );
        setUser(response.data.caregiver);

        return response.data;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // eslint-disable-next-line
  }, [data.careRecipient.primaryCaregiver]);

  async function sendCareRecipientData() {
    const client = createAxios();

    try {
      await client.post(
        '/api/Registration/registerMomentaryCareRecipientAsRealCareRecipient',
        {
          careRecipient: data.careRecipient,
          conditonsOfUseConfirmation: data.confirmation,
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  const portalUrl = process.env.REACT_APP_URL_PORTAL;

  sendCareRecipientData();
  return (
    <Container maxWidth={false}>
      <CareRecipientStepper currentStep={3} />
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography className={classes.title} variant="h6">
            {t('CareRecipientRegistration.Done.Title')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.Done.InfoText1')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.Done.InfoText2', {
              caregiverName:
                user !== undefined ? user.name.first : 'your inviter',
            })}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.Done.InfoText3')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.Done.InfoText4')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.Done.InfoText5')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.Done.InfoText6')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.Done.InfoText7')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.Done.InfoText8')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.Done.InfoText9')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('CareRecipientRegistration.Done.InfoText10')}
          </Typography>
          <Button variant="contained" color="primary" href={portalUrl}>
            {t('DonePage.TakeMeToPortal')}
          </Button>
        </Grid>
        {/* <Grid item lg={4} xs={12}>
          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
}
