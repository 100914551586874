import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { Button, Typography, Grid, Container, Card } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStateSelector } from '../state';
import { createAxios } from '../axios';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 15,
  },
  info: {
    paddingBottom: 15,
  },
}));

const CaregiverVoucherWelcomePage = ({ match }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [caregiverApiVoucher, setCaregiverApiVoucher] = React.useState();
  const [initialPersonalInfo, [setPersonalInfo, dispatch]] = useStateSelector(
    data => data.personalInfo
  );
  const caregiverVoucherCode = match.params.voucher;

  const onChangeStep = () => {
    dispatch({
      type: 'upCurrentStep',
    });
    dispatch({
      type: 'updatePersonalInfo',
      payload: { ...initialPersonalInfo },
    });
  };

  React.useEffect(() => {
    const client = createAxios();
    const fetchData = async () => {
      try {
        const response = await client.post(
          '/api/Registration/checkIfVoucherIsValid',
          {
            voucher: caregiverVoucherCode,
          }
        );

        if (response.data.isVoucherValid) {
          setCaregiverApiVoucher(response.data.voucher.couponId);
          setPersonalInfo({
            ...initialPersonalInfo,
            voucher: response.data.voucher.couponId,
          });
        }

        return response.data;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // eslint-disable-next-line
  }, [caregiverVoucherCode]);

  return (
    <Container maxWidth={false}>
      <Grid container justify="center" spacing={2}>
        {caregiverApiVoucher && initialPersonalInfo.voucher !== null ? (
          <Grid item lg={5} xs={12}>
            <Typography className={classes.title} variant="h6">
              {t('IndexPage.Title')}
            </Typography>
            <Typography className={classes.info} variant="subtitle1">
              {t('IndexPage.InfoText2')}
            </Typography>
            <Typography className={classes.info} variant="subtitle1">
              {t('IndexPage.InfoText3')}
            </Typography>
            <Button
              component={Link}
              to="/registration/personal-info"
              variant="contained"
              color="primary"
              onClick={onChangeStep}
            >
              {t('IndexPage.StartRegistration')}
            </Button>
          </Grid>
        ) : (
          <React.Fragment>
            <Card className={classes.card}>
              <Typography variant="subtitle2">
                {t('CaregiverVoucherWelcomepage.InvalidVoucherCode')}
              </Typography>
              <Typography variant="subtitle1">
                {t('CaregiverVoucherWelcomepage.GivenVoucherCode')}
              </Typography>
              <Typography variant="subtitle2">{caregiverApiVoucher}</Typography>
              <Typography variant="subtitle1">
                {t('CaregiverVoucherWelcomepage.Instructions')}
              </Typography>
            </Card>
          </React.Fragment>
        )}
        {/* <Grid item lg={4} xs={12}>
          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default withRouter(CaregiverVoucherWelcomePage);
