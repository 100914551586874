import React, { createContext, useContext, useReducer } from 'react';
import { DateTime } from 'luxon';

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);

export const useStateSelector = (selector = data => data) => {
  const [data, dispatch] = useStateValue();
  const [state, useState] = React.useState(selector(data));

  return [state, [useState, dispatch]];
};

export const initialState = {
  step: {
    currentStep: 0,
  },
  personalInfo: {
    voucher: '',
    firstname: '',
    lastname: '',
    address: '',
    secondAddress: '',
    city: '',
    stateProvinceRegion: '',
    zipPostal: '',
    timezone: DateTime.local().toFormat('z'),
  },
  accountInfo: {
    email: '',
    phoneNumber: '',
    password: '',
    passwordConfirm: '',
  },
  confirmation: {
    conditionsOfUse: false,
    privacyPolicy: false,
    date: '',
  },
  carerecipientInfo: [],
  careRecipient: {
    firstname: '',
    lastname: '',
    settings: {
      homeAddress: '',
      activeHoursStart: '07:00',
      activeHoursEnd: '21:00',
      homeAllDay: false,
      atHospital: false,
      emergencyBreakThruCalls: false,
      isAvailableInAnytime: false,
      isAvailableInMorning: false,
      isAvailableInAfternoon: false,
      isAvailableInEvening: false,
      isInterestedInEntertainment: false,
      isInterestedInNews: false,
      isInterestedInGamesPuzzle: false,
      isInterestedInHealthyLiving: false,
      isInterestedInHomeGardening: false,
      isInterestedInPolitics: false,
      isInterestedInSports: false,
      isInterestedInTechnology: false,
    },
    hasCarerecipientPermission: false,
    hasLeapThruPermission: false,
    email: '',
    age: '',
    phoneNumber: '',
    isPhoneSmarthpone: false,
    whatSmartphone: '',
    smartphoneMakeModel: '',
    adress: '',
    secondAddress: '',
    city: '',
    stateProvinceRegion: '',
    zipPostal: '',
    timezone: '',
    voucher: '',
    maritalStatus: '',
    relationship: '',
    residence: '',
    livingEnvironment: '',
    levelOfIndependence: '',
    orderSmartwatch: false,
    smartwatchDeliveryAddressCarerecipient: false,
    primaryCaregiver: '',
    personsOfInterests: [],
    password: '',
    passwordConfirm: '',
  },
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'upCurrentStep':
      return {
        ...state,
        step: { currentStep: (state.step.currentStep += 1) },
      };
    case 'downCurrentStep':
      return {
        ...state,
        step: { currentStep: (state.step.currentStep -= 1) },
      };
    case 'updatePersonalInfo':
      return {
        ...state,
        personalInfo: action.payload,
      };
    case 'updateAccountInfo':
      return {
        ...state,
        accountInfo: action.payload,
      };
    case 'updateCarerecipientInfo':
      const d = {
        ...state,
        carerecipientInfo: action.payload,
      };
      return d;
    case 'updateConfirmInfo':
      return {
        ...state,
        confirmation: action.payload,
      };

    case 'removeCareRecipient':
      const f = {
        ...state,
        carerecipientInfo: state.carerecipientInfo.splice(action.index, 1),
      };
      return f;
    case 'updateCareRecipient':
      return {
        ...state,
        careRecipient: action.payload,
      };
    default:
      return state;
  }
};
