export default function NewPersonOfInterestFormValidator(values) {
  const errors = {};

  if (values.firstname.length < 1) {
    errors.firstname = true;
  }
  if (values.lastname.length < 1) {
    errors.lastname = true;
  }
  if (values.phoneNumber.length < 1) {
    errors.phoneNumber = true;
  }

  return errors;
}
