import { isPossiblePhoneNumber } from 'react-phone-number-input';

export default function CareRecipientAccountRegistration(values) {
  const errors = {};

  if (values.firstname.length < 1) {
    errors.firstname = true;
  }
  if (values.lastname.length < 1) {
    errors.lastname = true;
  }
  if (values.adress.length < 1) {
    errors.address = true;
  }
  if (values.city.length < 1) {
    errors.city = true;
  }
  if (values.stateProvinceRegion.length < 1) {
    errors.stateProvinceRegion = true;
  }
  if (values.zipPostal.length < 1) {
    errors.zipPostal = true;
  }
  if (values.email.length < 1) {
    errors.email = true;
  }
  if (!isPossiblePhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = true;
  }
  if (values.password.length < 1) {
    errors.password = true;
  }
  if (values.passwordConfirm.length < 1) {
    errors.passwordConfirm = true;
  }

  if (values.passwordConfirm !== values.password) {
    errors.password = true;
    errors.passwordConfirm = true;
  }

  return errors;
}
