import React from 'react';
import { Route, Switch } from 'react-router-dom';
import IndexPage from 'pages/IndexPage';
import CaregiverVoucherWelcomePage from 'pages/CaregiverVoucherWelcomePage';
import AboutPage from 'pages/AboutPage';
import HelpPage from 'pages/HelpPage';
import RegistrationPersonalInfoPage from 'pages/RegistrationPersonalInfoPage';
import RegistrationAccountInfoPage from 'pages/RegistrationAccountInfoPage';
import RegistrationCarerecipientsPage from 'pages/RegistrationCarerecipientsPage';
import RegistrationConfirmationPage from 'pages/RegistrationConfirmationPage';
import ThankYouPage from 'pages/ThankYouPage';
import SettingsSetupPage from 'pages/SettingsSetupPage';
import DonePage from 'pages/DonePage';
import CareRecipientAccountRegistrationPage from 'pages/CareRecipientAccountRegistrationPage';
import CareRecipientAccountRegistrationConfirmationPage from 'pages/CareRecipientAccountRegistrationConfirmationPage';
import CareRecipientThankYouPage from 'pages/CareRecipientThankYouPage';
import CareRecipientAccountRegistrationSettingsPage from 'pages/CareRecipientAccountRegistrationSettingsPage';
import CareRecipientAccountRegistrationDonePage from 'pages/CareRecipientAccountRegistrationDonePage';
import HelpCareRecipientRegistrationPage from 'pages/HelpCareRecipientRegistrationPage';
import CareRecipientIndexPage from 'pages/CareRecipientIndexPage';
import NotFoundPage from 'pages/NotFoundPage';

export default (
  <Switch>
    <Route path="/" exact component={IndexPage} />
    <Route
      path="/welcome/:voucher"
      exact
      component={CaregiverVoucherWelcomePage}
    />
    <Route path="/home" exact component={IndexPage} />
    <Route path="/about" exact component={AboutPage} />
    <Route path="/help" exact component={HelpPage} />
    <Route
      path="/registration/personal-info"
      exact
      component={RegistrationPersonalInfoPage}
    />
    <Route
      path="/registration/account-info"
      exact
      component={RegistrationAccountInfoPage}
    />
    <Route
      path="/registration/carerecipients"
      exact
      component={RegistrationCarerecipientsPage}
    />
    <Route
      path="/registration/confirmation"
      exact
      component={RegistrationConfirmationPage}
    />
    <Route path="/thank-you" exact component={ThankYouPage} />
    <Route path="/settings-setup" exact component={SettingsSetupPage} />
    <Route
      path="/care-recipient-registration/start/:voucher"
      exact
      component={CareRecipientIndexPage}
    />
    <Route
      path="/care-recipient-registration/account/"
      exact
      component={CareRecipientAccountRegistrationPage}
    />
    <Route
      path="/care-recipient-registration/confirmation"
      exact
      component={CareRecipientAccountRegistrationConfirmationPage}
    />
    <Route
      path="/care-recipient/thank-you"
      exact
      component={CareRecipientThankYouPage}
    />
    <Route
      path="/care-recipient-registration/settings"
      exact
      component={CareRecipientAccountRegistrationSettingsPage}
    />
    <Route
      path="/care-recipient/done"
      exact
      component={CareRecipientAccountRegistrationDonePage}
    />
    <Route
      path="/help/care-recipient/registration"
      exact
      component={HelpCareRecipientRegistrationPage}
    />
    <Route path="/done" exact component={DonePage} />
    <Route path="*" component={NotFoundPage} />
  </Switch>
);
