import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import {
  IconButton,
  Button,
  Typography,
  Container,
  Grid,
} from '@material-ui/core';

import RegistrationConfirmationCareRecipients from 'components/RegistrationConfirmationCareRecipients/RegistrationConfirmationCareRecipients';

import { useTranslation } from 'react-i18next';
import { useStateSelector } from '../state';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import NewCareRecipientForm from 'forms/NewCareRecipientForm';
import { createAxios } from '../axios';
import CaregiverStepper from '../components/CaregiverStepper/CaregiverStepper';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 10,
    marginBottom: 25,
    marginTop: 20,
  },
}));

const RegistrationCarerecipientsPage = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [caregiverData] = useStateSelector(data => data.personalInfo);

  const caregiverVoucherCode = caregiverData.voucher;

  const [
    careRecipientsData,
    [setCareRecipientsData, dispatch],
  ] = useStateSelector(data => data.carerecipientInfo);

  const [
    newCareRecipientVoucherCode,
    setNewCareRecipientVoucherCode,
  ] = React.useState();

  const addCareRecipientInfoMessage = variant => {
    enqueueSnackbar('Oops.. Something went wrong, plase try again', {
      variant,
    });
  };

  async function generateVoucherCodeForCareRecipientUsingCareGiverVoucherCode(
    caregiverVoucherCode
  ) {
    const client = createAxios();

    try {
      const response = await client.post(
        '/api/Registration/generateVoucherCodeForCareRecipient',
        {
          caregiverVoucherCode: caregiverVoucherCode,
        }
      );
      if (response.data) {
        if (response.data.voucherCode) {
          const voucherCode = response.data.voucherCode;
          setNewCareRecipientVoucherCode(voucherCode);
          setIsOpen(true);
        }
      }
    } catch (error) {
      addCareRecipientInfoMessage('warning');
      console.error(error);
    }
  }

  const init = {};

  careRecipientsData.forEach((d, i) => {
    init[i] = false;
  });

  const [
    careRecipientDialogIsOpen,
    setCareRecipientDialogIsOpen,
  ] = React.useState(init);

  const handleClickOpen = () => {
    generateVoucherCodeForCareRecipientUsingCareGiverVoucherCode(
      caregiverVoucherCode
    );
  };

  const handleClickOpenCareRecipientDialog = index => {
    setCareRecipientDialogIsOpen({ [index]: true });
  };
  const handleOnRemove = indexToRemove => () => {
    setCareRecipientsData(orig =>
      orig.filter((_, index) => index !== indexToRemove)
    );
  };

  const onChangeNextStep = () => {
    dispatch({
      type: 'updateCarerecipientInfo',
      payload: careRecipientsData,
    });
    dispatch({
      type: 'upCurrentStep',
    });

    props.history.push('/registration/confirmation');
  };

  const onChangePreviousStep = () => {
    dispatch({
      type: 'downCurrentStep',
    });
  };

  const DeleteCareRecipient = ({ index }) => (
    <IconButton color="secondary" onClick={handleOnRemove(index)}>
      <DeleteIcon />
    </IconButton>
  );

  const EditCareRecipient = ({ index }) => {
    return (
      <React.Fragment>
        <IconButton onClick={() => handleClickOpenCareRecipientDialog(index)}>
          <EditIcon />
        </IconButton>

        <NewCareRecipientForm
          open={careRecipientDialogIsOpen[index]}
          onFormSubmitted={handleEditCareRecipient(index)}
          onFormCanceled={() =>
            setCareRecipientDialogIsOpen({ isOpen: { [index]: false } })
          }
          initialValues={careRecipientsData[index]}
          voucherCode={newCareRecipientVoucherCode}
        />
      </React.Fragment>
    );
  };

  const handleNewCareRecipient = info => {
    const careRecipientHomeAddress = `${info.address} ${info.secondAddress}, ${info.city}, ${info.stateProvinceRegion}, ${info.zipPostal}`;
    let defaultCareRecipientInfo = {
      ...info,
      settings: {
        homeAddress: careRecipientHomeAddress,
        activeHoursStart: '07:00',
        activeHoursEnd: '21:00',
        homeAllDay: false,
        atHospital: false,
        emergencyBreakThruCalls: false,
        isAvailableInAnytime: false,
        isAvailableInMorning: false,
        isAvailableInAfternoon: false,
        isAvailableInEvening: false,
        isInterestedInEntertainment: false,
        isInterestedInNews: false,
        isInterestedInGamesPuzzle: false,
        isInterestedInHealthyLiving: false,
        isInterestedInHomeGardening: false,
        isInterestedInPolitics: false,
        isInterestedInSports: false,
        isInterestedInTechnology: false,
      },
      personsOfInterests: [],
    };

    setCareRecipientsData(orig => orig.concat([defaultCareRecipientInfo]));

    dispatch({
      type: 'updateCarerecipientInfo',
      payload: careRecipientsData,
    });
    setIsOpen(false);
    setCareRecipientDialogIsOpen(false);

    const index = careRecipientsData.length - 1;

    setCareRecipientDialogIsOpen({
      [index]: false,
    });
  };

  const handleEditCareRecipient = index => careRecipientInfo => {
    careRecipientsData.splice(index, 1, {
      ...careRecipientInfo,
      settings: careRecipientsData[index].settings,
    });

    setCareRecipientsData(careRecipientsData);

    dispatch({
      type: 'updateCarerecipientInfo',
      payload: careRecipientsData,
    });
    setCareRecipientDialogIsOpen({ isOpen: { [index]: false } });
  };

  const handleNewCareRecipientCanceled = () => {
    setIsOpen(false);
    setCareRecipientDialogIsOpen(false);
  };

  const totalNumberOfCareRecipients = careRecipientsData.length;

  return (
    <Container maxWidth={false}>
      <CaregiverStepper currentStep={2} />
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography variant="h6">
            {t('Registration.Carerecipients.Title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('Registration.Carerecipients.InfoText')}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add Care Recipient
          </Button>
          <NewCareRecipientForm
            open={isOpen}
            onFormSubmitted={handleNewCareRecipient}
            onFormCanceled={handleNewCareRecipientCanceled}
            voucherCode={newCareRecipientVoucherCode}
          />
          <div className={classes.card}>
            {careRecipientsData.map((item, index) => (
              <Grid container key={index} justify="center" spacing={2}>
                <Grid item xs={10}>
                  <RegistrationConfirmationCareRecipients
                    key={index}
                    carerecipientInfo={item}
                    totalNumberOfCareRecipients={totalNumberOfCareRecipients}
                  />
                </Grid>
                <Grid item xs={1}>
                  <EditCareRecipient key={index} index={index} />
                </Grid>
                <Grid item xs={1}>
                  <DeleteCareRecipient key={index} index={index} />
                </Grid>
              </Grid>
            ))}
          </div>
          <Grid container>
            <Grid
              container
              item
              alignItems="flex-start"
              justify="flex-start"
              direction="row"
              xs={6}
            >
              <Button
                component={Link}
                to="/registration/account-info"
                variant="outlined"
                color="primary"
                onClick={onChangePreviousStep}
              >
                {t('Registration.Previous')}
              </Button>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-end"
              direction="row"
              item
              xs={6}
            >
              {careRecipientsData.length > 0 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onChangeNextStep}
                  type="button"
                >
                  {t('Registration.Next')}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item lg={4} xs={12}>
          <Typography variant="h6">Some text above the image</Typography>

          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default withRouter(RegistrationCarerecipientsPage);
