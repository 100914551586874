import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import makeHSL from 'styles/makeHSL';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 2,
  },

  routesContainer: {
    flexGrow: 2,
  },

  route: {
    textDecoration: 'none',
    color: makeHSL({ hue: 211, saturation: 0.15, lightness: 0.46 }),
    margin: '0 16px 0 16px',
    fontSize: 20,
    paddingBottom: 16,
    '&:hover': {
      color: makeHSL({ hue: 210, saturation: 0.55, lightness: 0.19 }),
      borderBottomWidth: 3,
      borderBottomStyle: 'solid',
      borderBottomColor: makeHSL({
        hue: 180,
        saturation: 0.6,
        lightness: 0.46,
      }),
    },
  },

  active: {
    color: makeHSL({ hue: 210, saturation: 0.55, lightness: 0.19 }),
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: makeHSL({ hue: 180, saturation: 0.6, lightness: 0.46 }),
  },
}));

const TopAppBarMenu = props => {
  const classes = useStyles();

  const menu = props.menuItems.map((item, index) => (
    <NavLink
      key={index}
      target={'_blank'}
      activeClassName={classes.active}
      className={classes.route}
      to={item.destination}
    >
      {item.name}
    </NavLink>
  ));

  return (
    <div className={classes.root}>
      <div className={classes.routesContainer}>{menu}</div>
    </div>
  );
};

export default TopAppBarMenu;
