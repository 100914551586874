import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import useForm from 'hooks/useForm';
import { validators } from 'validators';
import {
  Container,
  Card,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStateSelector } from '../state';
import { createAxios } from '../axios';
import StatesProvinceRegions from '../entities/statesProvinceRegions';
import CaregiverStepper from '../components/CaregiverStepper/CaregiverStepper';
import { useSnackbar } from 'notistack';
import timezoneOptionsList from '../entities/timezoneOptionsList';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 10,
    marginBottom: 25,
    marginTop: 20,
  },
  multipleSelectFields: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const caregiverVoucher = Object.freeze({
  couponId: '',
});

const RegistrationPersonalInfo = props => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [
    recievedCaregiverVoucherCode,
    setCaregiverRecievedVoucherCode,
  ] = React.useState(caregiverVoucher);

  const [voucherStepCompleted, setVoucherStepCompleted] = React.useState(false);

  const [initialPersonalInfo, [, dispatch]] = useStateSelector(
    data => data.personalInfo
  );

  const handleBadVoucherCode = variant => {
    enqueueSnackbar('Bad voucher code', {
      variant,
    });
  };

  async function checkVoucherCode(voucherCode) {
    const client = createAxios();

    try {
      const response = await client.post(
        '/api/Registration/checkIfVoucherIsValid',
        {
          voucher: voucherCode,
        }
      );

      if (response.data.isVoucherValid) {
        setCaregiverRecievedVoucherCode(response.data.voucher.couponId);
        setVoucherStepCompleted(true);
      }

      if (!response.data.isVoucherValid) {
        handleBadVoucherCode('warning');
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onChangeNextStep = () => {
    setValues(personalInfo);
    dispatch({
      type: 'updatePersonalInfo',
      payload: { ...personalInfo },
    });

    dispatch({
      type: 'upCurrentStep',
    });

    props.history.push('/registration/account-info');
  };

  const onChangePreviousStep = () => {
    dispatch({
      type: 'updatePersonalInfo',
      payload: { ...personalInfo },
    });

    dispatch({
      type: 'downCurrentStep',
    });
  };

  const [personalInfo, setValues, errors, handleChange, handleSubmit] = useForm(
    initialPersonalInfo,
    onChangeNextStep,
    validators.RegistrationPersonalInfoValidator
  );

  return (
    <Container maxWidth={false}>
      <CaregiverStepper
        currentStep={0}
        voucherStepCompleted={voucherStepCompleted}
      />
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container justify="center" spacing={2}>
          <Grid item lg={5} xs={12}>
            <Typography variant="h6">
              {t('Registration.PersonalInfo.Title')}
            </Typography>
            {personalInfo.voucher === recievedCaregiverVoucherCode ? (
              <React.Fragment>
                <Typography variant="subtitle1">
                  {t('Registration.PersonalInfo.InfoText')}
                </Typography>
                <Typography variant="subtitle1">
                  {t('Registration.PersonalInfo.InfoText2')}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant="subtitle1">
                  {t('Registration.PersonalInfo.VoucherCodeInfoText')}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ 'margin-top': 20 }}
                >
                  {t('Registration.PersonalInfo.VoucherCodeInfoText2')}
                </Typography>
              </React.Fragment>
            )}
            <Card className={classes.card}>
              <TextField
                error={errors.voucher}
                name="voucher"
                value={personalInfo.voucher}
                onChange={handleChange}
                label="Voucher Code"
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
              {personalInfo.voucher === recievedCaregiverVoucherCode ? null : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => checkVoucherCode(personalInfo.voucher)}
                >
                  Check voucher code
                </Button>
              )}
              {personalInfo.voucher === recievedCaregiverVoucherCode ? (
                <React.Fragment>
                  <TextField
                    error={Boolean(errors.firstname)}
                    name="firstname"
                    value={personalInfo.firstname}
                    onChange={handleChange}
                    label={t('Registration.PersonalInfo.Firstname')}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <TextField
                    error={Boolean(errors.lastname)}
                    name="lastname"
                    value={personalInfo.lastname}
                    onChange={handleChange}
                    label={t('Registration.PersonalInfo.Lastname')}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <TextField
                    error={Boolean(errors.address)}
                    name="address"
                    value={personalInfo.address}
                    onChange={handleChange}
                    label={t('Registration.PersonalInfo.Address')}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <TextField
                    error={Boolean(errors.secondAddress)}
                    name="secondAddress"
                    value={personalInfo.secondAddress}
                    onChange={handleChange}
                    label={t('Registration.PersonalInfo.SecondAddress')}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    error={Boolean(errors.city)}
                    name="city"
                    value={personalInfo.city}
                    onChange={handleChange}
                    label={t('Registration.PersonalInfo.City')}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <TextField
                    error={errors.stateProvinceRegion}
                    select
                    label={t('Registration.PersonalInfo.StateProvinceRegion')}
                    name="stateProvinceRegion"
                    value={personalInfo.stateProvinceRegion}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  >
                    {StatesProvinceRegions.map(item => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    error={Boolean(errors.zipPostal)}
                    name="zipPostal"
                    value={personalInfo.zipPostal}
                    onChange={handleChange}
                    label={t('Registration.PersonalInfo.ZipPostal')}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <TextField
                    error={errors.timezone}
                    select
                    label={t('Registration.PersonalInfo.Timezone')}
                    name="timezone"
                    value={personalInfo.timezone}
                    onChange={handleChange}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    required
                  >
                    {timezoneOptionsList.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </TextField>
                </React.Fragment>
              ) : null}
            </Card>
            <Grid container>
              <Grid
                container
                alignItems="flex-start"
                justify="flex-start"
                direction="row"
                item
                xs={6}
              >
                <Button
                  component={Link}
                  to="/"
                  variant="outlined"
                  color="primary"
                  onClick={onChangePreviousStep}
                >
                  {t('Registration.Previous')}
                </Button>
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                justify="flex-end"
                direction="row"
                item
                xs={6}
              >
                {personalInfo.voucher === recievedCaregiverVoucherCode ? (
                  <Button type="submit" variant="contained" color="primary">
                    {t('Registration.Next')}
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item lg={4} xs={12}>
            <Typography variant="h6">Some text above the image</Typography>

            <img src={logoleapthru} alt="Leapthru logo" />
          </Grid> */}
        </Grid>
      </form>
    </Container>
  );
};

export default withRouter(RegistrationPersonalInfo);
