export default function NewCareRecipientFormValidator(values) {
  const errors = {};

  if (values.firstname.length < 1) {
    errors.firstname = true;
  }
  if (values.lastname.length < 1) {
    errors.lastname = true;
  }
  if (values.email.length < 1) {
    errors.email = true;
  }
  if (values.age.length < 1) {
    errors.age = true;
  }
  if (values.phoneNumber.length < 1) {
    errors.phoneNumber = true;
  }
  if (values.address.length < 1) {
    errors.address = true;
  }
  if (values.city.length < 1) {
    errors.city = true;
  }
  if (values.stateProvinceRegion.length < 1) {
    errors.stateProvinceRegion = true;
  }
  if (values.zipPostal.length < 1) {
    errors.zipPostal = true;
  }
  if (values.timezone.length < 1) {
    errors.timezone = true;
  }

  return errors;
}
