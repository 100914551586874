import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { Typography, Grid, Container, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(4),
  },
}));

export default function AboutPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography className={classes.title} variant="h6">
            {t('AboutPageTitle')}
          </Typography>
          <Paper className={classes.content}>
            <Typography variant="subtitle1"></Typography>
            {t('AboutUsText')}
            <a href="https://leapthru.com/">https://leapthru.com/</a>
            <p>{t('MoreHelp')}</p>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
