import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import useForm from 'hooks/useForm';
import { validators } from 'validators';
import {
  Container,
  Card,
  Button,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStateSelector } from '../state';
import CareRecipientStepper from '../components/CareRecipientStepper/CareRecipientStepper';
import PhoneInput from 'react-phone-number-input';
import CustomPhoneInput from '../components/PhoneInput';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 10,
    marginBottom: 25,
    marginTop: 20,
  },
}));

const CareRecipientAccountRegistration = props => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [initialCareRecipient, [, dispatch]] = useStateSelector(
    data => data.careRecipient
  );

  const onChangeNextStep = () => {
    setValues(values);

    dispatch({
      type: 'updateCareRecipient',
      payload: { ...values },
    });

    props.history.push('/care-recipient-registration/confirmation');
  };

  const onChangePhoneNumber = phoneNumber => {
    setValues(values => ({
      ...values,
      phoneNumber,
    }));
  };

  const [values, setValues, errors, handleChange, handleSubmit] = useForm(
    initialCareRecipient,
    onChangeNextStep,
    validators.CareRecipientAccountRegistration
  );

  return (
    <Container maxWidth={false}>
      <CareRecipientStepper currentStep={0} />
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container justify="center" spacing={2}>
          <Grid item lg={5} xs={12}>
            <Typography variant="h6">
              {t('CareRecipientRegistration.AccountInfo.Title')}
            </Typography>
            <Typography variant="subtitle1">
              {t('CareRecipientRegistration.AccountInfo.InfoText')}
            </Typography>
            <Card className={classes.card}>
              <TextField
                error={errors.voucher}
                name="voucher"
                value={values.voucher}
                onChange={handleChange}
                label="Voucher Code"
                margin="normal"
                variant="outlined"
                fullWidth
                required
                disabled
              />
              <TextField
                error={Boolean(errors.firstname)}
                name="firstname"
                value={values.firstname}
                onChange={handleChange}
                label={t('Registration.PersonalInfo.Firstname')}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                error={Boolean(errors.lastname)}
                name="lastname"
                value={values.lastname}
                onChange={handleChange}
                label={t('Registration.PersonalInfo.Lastname')}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                error={Boolean(errors.address)}
                name="adress"
                value={values.adress}
                onChange={handleChange}
                label={t('Registration.PersonalInfo.Address')}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                error={Boolean(errors.secondAddress)}
                name="secondAddress"
                value={values.secondAddress}
                onChange={handleChange}
                label={t('Registration.PersonalInfo.SecondAddress')}
                margin="normal"
                variant="outlined"
                fullWidth
              />
              <TextField
                error={Boolean(errors.city)}
                name="city"
                value={values.city}
                onChange={handleChange}
                label={t('Registration.PersonalInfo.City')}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                error={Boolean(errors.stateProvinceRegion)}
                name="stateProvinceRegion"
                value={values.stateProvinceRegion}
                onChange={handleChange}
                label={t('Registration.PersonalInfo.StateProvinceRegion')}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                error={Boolean(errors.zipPostal)}
                name="zipPostal"
                value={values.zipPostal}
                onChange={handleChange}
                label={t('Registration.PersonalInfo.ZipPostal')}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
              <TextField
                error={Boolean(errors.age)}
                name="age"
                value={values.age}
                onChange={handleChange}
                label={t(
                  'Registration.Carerecipients.CarerecipientRegistration.Age'
                )}
                margin="normal"
                variant="outlined"
                type="number"
                fullWidth
              />
              <TextField
                error={Boolean(errors.email)}
                name="email"
                value={values.email}
                onChange={handleChange}
                label={t('Registration.AccountInfo.Email')}
                margin="normal"
                variant="outlined"
                type="email"
                fullWidth
                required
              />
              <PhoneInput
                placeholder="Enter phone number"
                value={values.phoneNumber || ''}
                onChange={onChangePhoneNumber}
                error={errors.phoneNumber || false}
                inputComponent={CustomPhoneInput}
                label={t('Registration.AccountInfo.PhoneNumber')}
                defaultCountry="US"
              />
              <TextField
                error={errors.password}
                name="password"
                value={values.password}
                onChange={handleChange}
                label={t('Registration.AccountInfo.Password')}
                margin="normal"
                variant="outlined"
                type="password"
                fullWidth
                required
              />
              <TextField
                error={errors.passwordConfirm}
                name="passwordConfirm"
                value={values.passwordConfirm}
                onChange={handleChange}
                label={t('Registration.AccountInfo.PasswordConfirm')}
                margin="normal"
                variant="outlined"
                type="password"
                fullWidth
                required
              />
            </Card>
            <Grid container>
              <Grid
                container
                alignItems="flex-start"
                justify="flex-start"
                direction="row"
                item
                xs={6}
              />
              <Grid
                container
                alignItems="flex-start"
                justify="flex-end"
                direction="row"
                item
                xs={6}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  // onClick={onChangeNextStep}
                  // component={Link}
                  // to="/care-recipient-registration/confirmation"
                >
                  {t('Registration.Next')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item lg={4} xs={12}>
            <Typography variant="h6">Some text above the image</Typography>

            <img src={logoleapthru} alt="Leapthru logo" />
          </Grid> */}
        </Grid>
      </form>
    </Container>
  );
};

export default withRouter(CareRecipientAccountRegistration);
