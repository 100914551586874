import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { validators } from 'validators';

import {
  Container,
  Card,
  Button,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  InputLabel,
  TextField,
  IconButton,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useStateSelector } from '../state';
import useForm from 'hooks/useForm';
import NewPersonOfInterestForm from 'forms/NewPersonOfInterestForm';
import PersonOfInterest from '../components/PersonOfInterest/PersonOfInterest';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CareRecipientStepper from '../components/CareRecipientStepper/CareRecipientStepper';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 10,
    marginBottom: 25,
    marginTop: 20,
  },
  marginBottomClass: {
    marginBottom: 15,
  },
}));

export default function CareRecipientAccountRegistrationSettingsPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const [careRecipient, [setCareRecipientData, dispatch]] = useStateSelector(
    data => data.careRecipient
  );

  const DeleteCareRecipient = ({ index }) => (
    <IconButton color="secondary" onClick={handleOnRemove(index)}>
      <DeleteIcon />
    </IconButton>
  );

  const handleClickOpen = () => {
    setIsOpen(true);
  };
  const handleNewPersonOfInterestCanceled = () => {
    setIsOpen(false);
  };
  const onChangeNextStep = () => {
    setValues(values);
    dispatch({
      type: 'updateCareRecipient',
      payload: {
        ...careRecipient,
        settings: { ...values },
      },
    });
  };

  const [values, setValues, , handleChange, handleSubmit] = useForm(
    careRecipient.settings,
    onChangeNextStep,
    validators.CareRecipientAccountRegistrationSettings
  );

  const timeFieldStepper = 300;

  const handleNewPersonOfInterest = info => {
    let defaultCareRecipient = {
      ...info,
    };

    dispatch({
      type: 'updateCareRecipient',
      payload: {
        ...careRecipient,
        personsOfInterests: careRecipient.personsOfInterests.push(
          defaultCareRecipient
        ),
      },
    });

    setIsOpen(false);
  };

  const handleOnRemove = indexToRemove => () => {
    setCareRecipientData(orig => ({
      ...orig,
      personsOfInterests: orig.personsOfInterests.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  return (
    <Container maxWidth={false}>
      <CareRecipientStepper currentStep={2} />
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container justify="center" spacing={2}>
          <Grid item lg={5} xs={12}>
            <Typography variant="h6">
              {t('CareRecipientRegistration.Settings.Title')}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ paddingBottom: 5 }}
            >
              {t('SettingsSetupPage.InfoTextCareRecipient')}
            </Typography>
            <Card className={classes.card}>
              <Grid item xs={12}>
                <TextField
                  name="homeAddress"
                  value={values.homeAddress}
                  onChange={handleChange}
                  label={t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.HomeAddress'
                  )}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                <Grid container spacing={2} justify="center">
                  <Grid item xs={6}>
                    <TextField
                      name="activeHoursStart"
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.GenerallyWakingUp'
                      )}
                      type="time"
                      defaultValue={values.activeHoursStart}
                      value={values.activeHoursStart}
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: timeFieldStepper,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="activeHoursEnd"
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.GenerallyGoingToBed'
                      )}
                      type="time"
                      defaultValue={values.activeHoursEnd}
                      value={values.activeHoursEnd}
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: timeFieldStepper,
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="homeAllDay"
                        onChange={handleChange}
                        checked={values.homeAllDay}
                        value={values.homeAllDay}
                        color="primary"
                      />
                    }
                    label={t(
                      'SettingsSetupPage.CarerecipientsSettingsSetup.HomeAllDay'
                    )}
                    labelPlacement="end"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="atHospital"
                        onChange={handleChange}
                        checked={values.atHospital}
                        value={values.atHospital}
                        color="primary"
                      />
                    }
                    label={t(
                      'SettingsSetupPage.CarerecipientsSettingsSetup.AtHospital'
                    )}
                    labelPlacement="end"
                  />
                </Grid>

                <Grid item xs={12} className={classes.marginBottomClass}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="emergencyBreakThruCalls"
                        onChange={handleChange}
                        checked={values.emergencyBreakThruCalls}
                        value={values.emergencyBreakThruCalls}
                        color="primary"
                      />
                    }
                    label={t(
                      'SettingsSetupPage.CarerecipientsSettingsSetup.EmergencyBreakThruCalls'
                    )}
                    labelPlacement="end"
                  />
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: 10 }}
                  >
                    {t(
                      'SettingsSetupPage.CarerecipientsSettingsSetup.BreakThruInfoText'
                    )}
                  </Typography>
                </Grid>

                <InputLabel
                  htmlFor="select-multiple-checkbox"
                  className={classes.marginBottomClass}
                >
                  {t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.PersonsOfInterests'
                  )}
                </InputLabel>

                <Typography variant="body1" style={{ paddingBottom: 15 }}>
                  {t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.PersonsOfInterestsInfoTextCareRecipient'
                  )}
                </Typography>

                <Button
                  className={classes.marginBottomClass}
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                >
                  {t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.AddPersonOfInterest'
                  )}
                </Button>

                <NewPersonOfInterestForm
                  open={isOpen}
                  onFormSubmitted={handleNewPersonOfInterest}
                  onFormCanceled={handleNewPersonOfInterestCanceled}
                  isCareRecipient={true}
                />

                {careRecipient.personsOfInterests.length > 0 ? (
                  <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="subtitle2">
                        {t(
                          'SettingsSetupPage.CarerecipientsSettingsSetup.PersonsOfInterests'
                        )}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid justify="center" spacing={2} item xs={12}>
                        {careRecipient.personsOfInterests.map(
                          (personOfInterest, index) => (
                            <React.Fragment>
                              <Grid container item xs={12}>
                                <Grid item xs={11}>
                                  <PersonOfInterest
                                    personOfInterest={personOfInterest}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <DeleteCareRecipient
                                    key={index}
                                    index={index}
                                  />
                                </Grid>
                              </Grid>
                              <Divider />
                            </React.Fragment>
                          )
                        )}
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ) : null}
                <br />
                <InputLabel htmlFor="select-multiple-checkbox">
                  {t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.BestTimes.Title'
                  )}
                </InputLabel>
                <Grid
                  container
                  justify="center"
                  spacing={2}
                  className={classes.marginBottomClass}
                >
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isAvailableInAnytime"
                          onChange={handleChange}
                          checked={values.isAvailableInAnytime}
                          value={values.isAvailableInAnytime}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.BestTimes.Anytime'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isAvailableInMorning"
                          onChange={handleChange}
                          checked={values.isAvailableInMorning}
                          value={values.isAvailableInMorning}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.BestTimes.Morning'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isAvailableInAfternoon"
                          onChange={handleChange}
                          checked={values.isAvailableInAfternoon}
                          value={values.isAvailableInAfternoon}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.BestTimes.Afternoon'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isAvailableInEvening"
                          onChange={handleChange}
                          checked={values.isAvailableInEvening}
                          value={values.isAvailableInEvening}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.BestTimes.Evening'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>

                <InputLabel htmlFor="select-multiple-checkbox">
                  {t(
                    'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.Title'
                  )}
                </InputLabel>

                <Grid container justify="center" spacing={2}>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isInterestedInEntertainment"
                          onChange={handleChange}
                          checked={values.isInterestedInEntertainment}
                          value={values.isInterestedInEntertainment}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.Entertainment'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isInterestedInNews"
                          onChange={handleChange}
                          checked={values.isInterestedInNews}
                          value={values.isInterestedInNews}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.News'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isInterestedInGamesPuzzle"
                          onChange={handleChange}
                          checked={values.isInterestedInGamesPuzzle}
                          value={values.isInterestedInGamesPuzzle}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.GamesPuzzle'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isInterestedInHealthyLiving"
                          onChange={handleChange}
                          checked={values.isInterestedInHealthyLiving}
                          value={values.isInterestedInHealthyLiving}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.HealthyLiving'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>

                <Grid container justify="center" spacing={2}>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isInterestedInHomeGardening"
                          onChange={handleChange}
                          checked={values.isInterestedInHomeGardening}
                          value={values.isInterestedInHomeGardening}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.HomeGarden'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isInterestedInPolitics"
                          onChange={handleChange}
                          checked={values.isInterestedInPolitics}
                          value={values.isInterestedInPolitics}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.Politics'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isInterestedInSports"
                          onChange={handleChange}
                          checked={values.isInterestedInSports}
                          value={values.isInterestedInSports}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.Sports'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isInterestedInTechnology"
                          onChange={handleChange}
                          checked={values.isInterestedInTechnology}
                          value={values.isInterestedInTechnology}
                          color="primary"
                        />
                      }
                      label={t(
                        'SettingsSetupPage.CarerecipientsSettingsSetup.Interests.Technology'
                      )}
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Card>
            <Grid container>
              <Grid
                container
                alignItems="flex-start"
                justify="flex-start"
                direction="row"
                item
                xs={6}
              >
                <Button
                  component={Link}
                  to="/care-recipient/thank-you"
                  variant="contained"
                  color="primary"
                >
                  {t('SettingsSetupPage.Back')}
                </Button>
              </Grid>
              <Grid
                container
                alignItems="flex-start"
                justify="flex-end"
                direction="row"
                item
                xs={6}
              >
                <Button
                  component={Link}
                  to="/care-recipient/done"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={onChangeNextStep}
                >
                  {t('SettingsSetupPage.Submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item lg={4} xs={12}>
            <Typography variant="h6">Some text above the image</Typography>

            <img src={logoleapthru} alt="Leapthru logo" />
          </Grid> */}
        </Grid>
      </form>
    </Container>
  );
}
