import React from 'react';
import {
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  DialogTitle,
  Typography,
  ListItem,
  List,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  privacyPolicy,
  termsAndConditions,
} from './TermsConditionsPrivacyPolicy.js';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function CompanyPolicyDialog(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const finalText =
    props.type === 'privacyPolicy' ? privacyPolicy : termsAndConditions;

  const createSlug = title => {
    return title.toLowerCase().replace(/\W/g, '-');
  };

  const renderers = {
    heading: ({ level, children }) => {
      const text = children[0].props.value;
      const slug = createSlug(text);

      return (
        <Typography id={slug} variant={`h${level}`}>
          {children}
        </Typography>
      );
    },
    listItem: ({ level, children }) => {
      return <ListItem>• {children}</ListItem>;
    },
    list: ({ level, children }) => {
      return <List>{children}</List>;
    },
  };

  const markdown = <ReactMarkdown renderers={renderers} source={finalText} />;

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.type === 'privacyPolicy'
            ? t('PrivacyPolicyTitle')
            : t('TermsConditionsTitle')}
        </DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={() => props.onClose()}
          color="primary"
          autoFocus
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {markdown}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CompanyPolicyDialog;
