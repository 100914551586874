import {
  faInbox as fasInbox,
  faInfoCircle as fasInfoCircle,
  faQuestionCircle as fasQuestionCircle,
  faHeart as fasFaHeart,
  faBatteryFull as fasFaBatteryFull,
  faBatterySlash as fasFaBatterySlash,
  faBolt as fasFaBolt,
  faChevronDown as fasFaChevronDown,
  faConciergeBell as fasFaConciergeBell,
  faExclamationCircle as fasFaExclamationCircle,
  faPlus as fasFaPlus,
  faHome as fasFaHome,
  faCalendar as fasFaCalendar,
  faMapMarkerCheck as fasFaMapMarkerCheck,
  faSignalAlt3 as fasFaSignalAlt3,
  faAlarmClock as fasFaAlarmClock,
  faAddressBook as fasFaAddressBook,
  faUserNurse as fasFaUserNurse,
  faCog as fasFaCog,
  faCommentCheck as fasFaCommentCheck,
  faCommentTimes as fasFaCommentTimes,
  faCommentDots as fasFaCommentDots,
  faCommentAlt as fasFaCommentAlt,
  faIdCard as fasFaIdCard,
  faUsers as fasFaUsers,
  faBells as fasFaBells,
  faTaxi as fasFaTaxi,
  faLifeRing as fasFaLifeRing,
  faSignOutAlt as fasFaSignOutAlt,
  faLightbulbOn as fasFaLightbulbOn,
  faStream as fasFaStream,
  faCheck as fasFaCheck,
  faUserFriends as fasFaUserFriends,
  faInfoSquare as fasInfoSquare,
  faCogs as fasCogs,
  faEye as fasEye,
} from '@fortawesome/pro-solid-svg-icons';

import { faCapsules as farCapsules } from '@fortawesome/pro-regular-svg-icons';

import {
  faAddressBook as falFaAddressBook,
  faInbox as falFaInbox,
  faBell as falFaBell,
  faCalendar as falFaCalendarAlt,
  faBells as falFaBells,
  faFont as falFaFont,
  faHeartbeat as falFaHeartbeat,
  faHandshake as falFaHandshake,
  faHomeHeart as falFaHomeHeart,
  faLifeRing as falFaLifeRing,
  faMapMarkerAlt as falFaMapMarkerAlt,
  faMapMarkedAlt as falFaMapMarkedAlt,
  faUserCircle as falFaUserCircle,
  faQuestionCircle as falFaQuestionCircle,
  faChevronDown as falFaChevronDown,
  faBars as falFaBars,
  faChevronRight as falFaChevronRight,
  faShoePrints as falFaShoePrints,
  faSpa as falFaSpa,
  faStream as falFaStream,
  faPrescriptionBottleAlt as falFaPrescriptionBottleAlt,
  faInfoCircle as falFaInfoCircle,
  faLaughBeam as falFaLaughBeam,
} from '@fortawesome/pro-light-svg-icons';

export default {
  // 'pro solid icons'
  fasInbox,
  fasInfoCircle,
  fasQuestionCircle,
  fasFaHeart,
  fasFaBatteryFull,
  fasFaBatterySlash,
  fasFaBolt,
  fasFaChevronDown,
  fasFaConciergeBell,
  fasFaExclamationCircle,
  fasFaCommentCheck,
  fasFaCommentTimes,
  fasFaCommentDots,
  fasFaCommentAlt,
  fasFaPlus,
  fasFaHome,
  fasFaSignalAlt3,
  fasFaAlarmClock,
  fasFaAddressBook,
  fasFaUserNurse,
  fasFaUserFriends,
  fasFaMapMarkerCheck,
  fasFaUsers,
  fasFaCog,
  fasFaIdCard,
  fasFaCalendar,
  fasFaBells,
  fasFaTaxi,
  fasFaLifeRing,
  fasFaSignOutAlt,
  fasFaLightbulbOn,
  fasFaStream,
  fasFaCheck,
  fasInfoSquare,
  fasCogs,
  fasEye,

  // 'pro regular icons'
  farCapsules,

  // 'pro light icons'
  falFaAddressBook,
  falFaInbox,
  falFaBell,
  falFaCalendarAlt,
  falFaBells,
  falFaFont,
  falFaHeartbeat,
  falFaHandshake,
  falFaHomeHeart,
  falFaLifeRing,
  falFaMapMarkerAlt,
  falFaMapMarkedAlt,
  falFaUserCircle,
  falFaQuestionCircle,
  falFaChevronDown,
  falFaBars,
  falFaChevronRight,
  falFaShoePrints,
  falFaSpa,
  falFaStream,
  falFaPrescriptionBottleAlt,
  falFaInfoCircle,
  falFaLaughBeam,
};
