import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography, Grid, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import CaregiverStepper from '../components/CaregiverStepper/CaregiverStepper';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 15,
  },
  info: {
    paddingBottom: 15,
  },
}));

export default function DonePage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const portalUrl = process.env.REACT_APP_URL_PORTAL;

  return (
    <Container maxWidth={false}>
      <CaregiverStepper currentStep={5} />
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography className={classes.title} variant="h6">
            {t('DonePage.Title')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('DonePage.InfoText1')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('DonePage.InfoText2')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('DonePage.InfoText3')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('DonePage.InfoText4')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('DonePage.InfoText5')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('DonePage.InfoText6')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('DonePage.InfoText7')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('DonePage.InfoText8')}
          </Typography>
          <Typography className={classes.info} variant="body1" gutterBottom>
            {t('DonePage.InfoText9')}
          </Typography>
          <Grid container>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-start"
              direction="row"
              item
              xs={5}
            >
              <Button variant="contained" color="primary" href={portalUrl}>
                {t('DonePage.TakeMeToPortal')}
              </Button>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-end"
              direction="row"
              item
              xs={7}
            >
              <Button
                component={Link}
                to="/help/care-recipient/registration"
                variant="contained"
                color="primary"
              >
                {t('DonePage.HelpCareRecipients')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item lg={4} xs={12}>
          <img src={logoleapthru} alt="Leapthru logo" />
        </Grid> */}
      </Grid>
    </Container>
  );
}
