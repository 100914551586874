import RegistrationPersonalInfoValidator from 'validators/RegistrationPersonalInfo';
import AccountInfoValidation from 'validators/AccountInfoValidation';
import RegistrationCareRecipients from 'validators/RegistrationCareRecipients';
import CareRecipientAccountRegistration from 'validators/CareRecipientAccountRegistration';
import CareRecipientAccountRegistrationSettings from 'validators/CareRecipientAccountRegistrationSettings';

export const validators = {
  RegistrationPersonalInfoValidator,
  AccountInfoValidation,
  RegistrationCareRecipients,
  CareRecipientAccountRegistration,
  CareRecipientAccountRegistrationSettings,
};
