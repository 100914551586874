import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Typography,
  Grid,
  Container,
  Button,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(4),
  },
  transparent: {
    color: `#333`,
    backgroundColor: 'transparent',
  },
}));

const helpLinks = [
  {
    title: 'Registration and setup',
    link: 'https://leapthru.com/knowledge-base/registration-setup-overview/',
    icon: 'user-friends',
  },
  {
    title: 'Overview of the LeapConnect™ Caregiving Platform',
    link:
      'https://leapthru.com/knowledge-base/leapthru-leapconnect-caregiving-platform-overview/',
    icon: 'eye',
  },
  {
    title: 'System requirements',
    link: 'https://leapthru.com/knowledge-base/sys-requirements/',
    icon: 'cogs',
  },

  {
    title: 'Knowledge base',
    link: 'https://leapthru.com/knowledge-base/',
    icon: 'info-square',
  },
];

export default function HelpPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <Grid container justify="center" spacing={2}>
        <Grid item lg={5} xs={12}>
          <Typography className={classes.title} variant="h6">
            {t('HelpPageTitle')}
          </Typography>
          <Paper className={classes.content} style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1">
              (Pages open in a new window or tab)
            </Typography>
            <List>
              {helpLinks.map(helpLink => {
                return (
                  <ListItem
                    button
                    component="a"
                    href={helpLink.link}
                    target="_blank"
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.transparent}>
                        <FontAwesomeIcon icon={helpLink.icon} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={helpLink.title} />
                  </ListItem>
                );
              })}
            </List>
          </Paper>
          <Paper className={classes.content}>
            <Typography variant="subtitle1">{t('HelpText')}</Typography>
            <p>
              <b>{t('Email1')}: </b>
              <a href="ltsupport@leapthru.com">connect@leapthru.com</a>
            </p>
            <p>
              <b>{t('Email2')}: </b>
              <a href="ltsupport@leapthru.com">ltsupport@leapthru.com</a>
            </p>
            <p>
              <b>{t('Phone')}: </b>
              (833) 532-7123
            </p>
            <Grid item lg={12} xs={12}>
              <Button
                onClick={() => window.$crisp.push(['do', 'chat:open'])}
                variant="outlined"
                color="primary"
              >
                {t('ChatWithSupport')}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
